import { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import { CheckBox } from '../../FormInput/FormInput';
import * as styles from '../../../styles/scss/components/LobbySetupActions/LobbySetupActions.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { cdnLoader } from '../../../config/cdnImageLoader.config';

const LobbyGameSetupActions = (props) => {
  const {privateLobby, sidebarData, toggleSidebar} = props
  const {t} = useTranslation();
  const [chosenRules, setChosenRules] = useState([]);
  const [dropdownData, setDropdownData] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  useEffect(() => {
    if (sidebarData.setupName === 'rules') {
      setChosenRules(privateLobby.selectedRules);
    } else if (sidebarData.setupName === 'mode') {
      setDropdownData([...privateLobby.gameModes.filter(item => item.enabled)]);
      setSelectedValue(privateLobby.selectedGameMode);
    } else if (sidebarData.setupName === 'playerNumber') {
      setDropdownData([...privateLobby.playersNumbers.filter(item => item.value <= 4)]);
      setSelectedValue(privateLobby.selectedPlayerNumber);
    } 
  },[])
  
  const handlRuleSelect = (rule) => {
    setChosenRules(prevState => {
      let newSelectedRules = [...prevState]

      if (newSelectedRules.findIndex(elem => elem.id === rule.id) < 0) {
        newSelectedRules = [...newSelectedRules, rule]
      } else {
        newSelectedRules = [...newSelectedRules.filter(selectedRule => selectedRule.id !== rule.id)]
      }
      return [...newSelectedRules]
    })
  }

  return (
    <div className = {styles.lobby_actions_sidebar}>
      <div className = {styles.setup_dropdown}>
        <ul className = {`${styles.setup_dropdown_ul} ${styles.setup_dropdown_ul_landscape}`}>
          {sidebarData.setupName === 'rules' && (
            <>
            <li className = {`${styles.setup_dropdown_li} ${styles.rules}`}>
              <div className = {`${styles.setup_item} ${styles.active}`} >
                <div className = {styles.check_container}>
                  <CheckBox checked = {true}/>
                </div>
                <div className = {styles.item_data}>
                  <img src = {cdnLoader(`/images/rules/sbd.svg`)} className = {styles.item_image} />
                  <h3 className = {styles.item_text}>
                    {t('common:sidebar_body.lobby_sidebar.rules.sbd')}
                  </h3>
                </div>
              </div>
            </li>
            {privateLobby.avilableRules.map((rule, index) => {
              const active = chosenRules.findIndex(elem => elem.id === rule.id) >= 0
              if (privateLobby.selectedGameMode.value === 'team' && (rule.symbol === 'js' || rule.symbol === 'ccs')) return
              if (privateLobby.selectedGameMode.value === 'solo' && (rule.symbol === 'cs' || rule.symbol === 'ccs')) return
              return (
                <li key={index} className = {`${styles.setup_dropdown_li} ${styles.rules}`}>
                  <div className = {`${styles.setup_item} ${active && styles.active}`} onClick = {() => {handlRuleSelect(rule)}}>
                    <div className = {styles.check_container}>
                      <CheckBox checked = {active}/>
                    </div>
                    <div className = {styles.item_data}>
                      <img src = {cdnLoader(`/images/rules/${rule.symbol}.svg`)} className = {styles.item_image} />
                      <h3 className = {styles.item_text}>
                        {t(`common:sidebar_body.lobby_sidebar.rules.${rule.symbol}`)}
                      </h3>
                    </div>
                  </div>
                </li>
              )
            })}
          </>
          )}
          {((sidebarData.setupName === 'mode' || sidebarData.setupName === 'versus' || sidebarData.setupName === 'playerNumber') && !!dropdownData) && 
            dropdownData.map(item => {
              return (
                <li className = {styles.setup_dropdown_li} key = {`dropdownItem${item.value}`}>
                  <div className = {`${styles.setup_item} ${selectedValue?.value === item.value && styles.active}`} onClick = {() => {toggleSidebar({isOpen: false}),sidebarData.saveSelectedValue(item)}}>
                    <div className = {styles.item_data}>
                      <h3 className = {styles.item_text}>
                        {sidebarData.setupName === 'mode' ? t(`common:sidebar_body.lobby_sidebar.mode.${item.value}`): item.label}
                      </h3>
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ul>
        {sidebarData.setupName === 'rules' && (
          <div className = {styles.save_button_container}>
            <button onClick = {() => {
              toggleSidebar({isOpen: false});
              sidebarData.saveSelectedValue(chosenRules)
              }}>Save</button>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    privateLobby: state.privateLobby,
    sidebarData: state.modals.sidebarData
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LobbyGameSetupActions);