import { useEffect, useState } from 'react';
import api from '../../axios/api';
import * as styles from '../../styles/scss/components/Chat/Predefined.module.scss';
import Countdown from '../Countdown/CountDown';
import ClockSvg from '../../public/images/common/Clock.svg'
import useTranslation from 'next-translate/useTranslation';
import { cdnLoader } from '../../config/cdnImageLoader.config';
const PredefinedChat = (props) => {
  const {
    chatId, 
    predefinedTexts, 
    closeChat, 
    delayIsActicve, 
    closeDelay,
    setDelayIsActive,
    onSetPlayerMessage,
    socketId,
    isMobile
  } = props
  const [emoji, setEmoji] = useState(null);
  const [texts, setTexts] = useState(null);
  const [currentTab, setCurrentTab] = useState('emoji')
  const {t} = useTranslation()
  const isEmoji = (text) => {
    if (text[0] === ':' && text[text.length - 1] === ':') {
      return true
    }
    return false
  }

  const handleSendMessage = (messageId) => {
    if (delayIsActicve) {
      return
    }
    const chatMessageBody = {
      body: messageId
    }
    setDelayIsActive(true)
    api.defaults.headers.common['X-Socket-ID'] = socketId
    api.post(`/chat/${chatId}`, chatMessageBody)
    .then(response => {
      if (isMobile) {
        closeChat()
      }
      onSetPlayerMessage({
        userId: response.data.userId,
        message: response.data.body
      })
      // closeDelay()
      // closeChat()
      // setMessageData([...messageData, {...response}])
      // setMessageText('');
    })
    .catch(e =>{
      console.log(e);
    })
  }

  useEffect(() => {
    const chatPredefinedTexts = [...predefinedTexts];
    const texts = [];
    const emoji = [];
    for (let element of chatPredefinedTexts) {
      if (isEmoji(element)) {
        emoji.push({
          id: element,
          name: element.substring(1, element.length - 1)
        })
      } else {
        texts.push({
          id: element,
          text: element
        })
      }
    }
    // const regEx = /\:\w+\:/
    // setEmoji(() => [...chatPredefinedTexts.filter(element => regEx.test(element))
    //   .map(element => {
    //   return {
    //     id: element,
    //     name: element.substring(1, element.length - 1)
    //   }
    // })])
    setEmoji(() => [...emoji]);
    setTexts(() => [...texts]);
    // const chatChannel = pusher.subscribe(`Chat.${chatId}`);
    // chatChannel.bind('chat.message', data => {
    // })
    // return () => {
    //   pusher.unsubscribe(`Chat.${chatId}`)
    // }
  }, [])

  return (
    <div className = {`${styles.chat_module_container} chat_module_container ${isMobile ? styles.mobile : styles.desktop}`}>
      <div className = {styles.chat_module_header}>
        <h2 className = {styles.modal_title}>{t('chat_texts:show_emotions')}</h2>
        <div
          className = {`${styles.close_button}
          ${styles.chat_head_button}`}
          onClick = {closeChat}
        >
          <img src={cdnLoader('/images/common/close.png')} />
        </div>
      </div>
      <div className = {styles.chat_modal_body}>
        {isMobile && (
          <div className={styles.tab_container}>
            <button className={`${styles.tab_button} ${currentTab === 'emoji' ? styles.active: ''}`} onClick={() => {setCurrentTab('emoji')}}>
              <span>{t('chat_texts:emojis')}</span>
            </button>
            <button className={`${styles.tab_button} ${currentTab === 'text' ? styles.active: ''}`} onClick={() => {setCurrentTab('text')}}>
              <span>{t('chat_texts:phrases')}</span>
            </button>
          </div>
        )}
        {delayIsActicve && (
          <div className= {styles.chat_delay_panel}>
            <div className = {styles.chat_delay_content}>
              <span className = {`${styles.text}`}>{t('common:modals.game_search.please_wait')}</span>
              {!isMobile && <>
                <ClockSvg className={styles.clock_image} />
                <span className = {`${styles.text} ${styles.time}`}>
                  <Countdown callBackFunc = {closeDelay} time = {3} type="html" />
                </span>
              </>}
            </div>
          </div>
        )}
        {(!!emoji && ((isMobile && currentTab === 'emoji') || !isMobile)) && (
          <EmojiComponent emoji={emoji} handleSendMessage={handleSendMessage} />
        )}
        {(!!texts && ((isMobile && currentTab === 'text') || !isMobile)) && (
          <TextMessageComponent texts={texts} handleSendMessage={handleSendMessage} />          
        )}
      </div>
    </div>
  )
}

const EmojiComponent = ({emoji, handleSendMessage}) => {
  return (
    <div className = {styles.chat_emoji}>
      <ul className = {styles.chat_emoji_ul}>
        {emoji.map(singleEmoji => {
          return (
            <li
              className = {styles.chat_emoji_li}
              key={singleEmoji.id}
              onClick = {() => {handleSendMessage(singleEmoji.id)}}  
            >
              <img src={cdnLoader(`/images/emoji/${singleEmoji.name}.svg`)} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const TextMessageComponent = ({texts, handleSendMessage}) => {
  const {t} = useTranslation()
  return (
    <div className = {styles.chat_texts}>
      <ul className = {styles.chat_texts_ul}>
        {texts.map(text => {
          return (
            <li
              className = {styles.chat_text_li}
              key={text.id}
              onClick = {() => {handleSendMessage(text.id)}}
            >
              <span className = {styles.chat_text}>
                {t(`chat_texts:${text.text}`)}
              </span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default PredefinedChat