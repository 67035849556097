import React from "react";
import playerStyles from "./styles.module.scss";
import Modal from "../Modal/Modal";
import Avatar from "../Avatar/Avatar";
import Loader from '../CustomLoader/Loader';
import { CloseButton } from "../Buttons/Buttons";
import Backdrop from "../Backdrop/Backdrop";
import useTranslation from "next-translate/useTranslation";
import { cdnLoader } from "../../config/cdnImageLoader.config";
export const RematchModal = ({
  setRematchModal,
  gameData,
  rematchGame,
  closeModal,
}) => {

  const {t} = useTranslation()
  const [title, setTitle] = React.useState('');
  const [scoreboardSort, setScoreboardSort] = React.useState([]);
  const timeout = setTimeout(() => {
    window.location.href = '/'
  }, 900000)
  const viewTitle = () => {
    return gameData.mode === "solo" ? (
      gameData.scoreboard.find(
        (player) =>
          player.won && player.player.id === gameData.currentPlayer.id
      ) ? (
        <h1 className={playerStyles.winner + " " + playerStyles.title}>
          {t('common:modals.rematch_modal.title_solo')}
        </h1>
      ) : (
        <h1 className={playerStyles.title}>
          {gameData.scoreboard.find((player) => player.won).player.user.name +
            " " +
            t('common:modals.rematch_modal.won')}
        </h1>
      )
    ) : gameData.scoreboard.find(
        (player) =>
          player.won && player.player.id === gameData.currentPlayer.id
      ) ? (
      <h1 className={playerStyles.title}>{t('common:modals.rematch_modal.title_team')}</h1>
    ) : (
      <h1 className={playerStyles.title}>
        {gameData.scoreboard.find((player) => player.won)?.team?.name} {t('common:modals.rematch_modal.won')}
      </h1>
    )
  }

  React.useEffect(() => {
    setTitle(viewTitle());
    setScoreboardSort(gameData.scoreboard.sort((a, b) => b.won - a.won || b.finishedMarbles - a.finishedMarbles));
  }, []);

  return (
    <>
    <Backdrop />
    <Modal
      header={false}
      additionalClass={playerStyles["rematch-modal"] }
      handleClose={() => setRematchModal((oldState) => !oldState)}
    >
      {title}
      <div className={playerStyles["info-container"]}>
        <div>
          <p>{t('common:modals.rematch_modal.rounds')}</p>
          <div className={playerStyles.indicator}>{gameData.scoreboard[0].rounds}</div>
        </div>
        <div>
          <p>{t('common:modals.rematch_modal.duration')}</p>
          <div className={playerStyles.indicator}>{
 
          (Math.floor(gameData.scoreboard[0].duration / 60)?Math.floor(gameData.scoreboard[0].duration / 60) + t('common:modals.rematch_modal.hour_abbreviation'): "") + " " +  (gameData.scoreboard[0].duration % 60)+ t('common:modals.rematch_modal.minutes_abbreviation')}</div>
        </div>
      </div>

      <table className={playerStyles["desktop-table"]} >
        <tr className={playerStyles["desktop-table-header"]} style={{ position: "relative" }}>
          <th>{t('common:modals.rematch_modal.place')}</th>
          <th>{t('common:modals.rematch_modal.player')}</th>
          <th>{t('common:modals.rematch_modal.played')}</th>
          <th>{t('common:modals.rematch_modal.wins')}</th>
          <th>{t('common:modals.rematch_modal.losses')}</th>
          {
            gameData.type === 'classic' && gameData.mode === 'team' &&
              <th>{t('common:modals.rematch_modal.ranking_avg')}</th> 
          }
          {
            gameData.type === 'classic' && gameData.mode === 'solo' &&
              <th>{t('common:modals.rematch_modal.ranking')}</th>
          }
          <img
            style={{ position: "absolute", left: "-26px", top: "38px" }}
            src={cdnLoader("/images/cup.svg")}
          />
        </tr>
        <tr className={playerStyles["mobile-table-header"]}>
          <th></th>
          <th style={{height: '90px'}}></th>
          <th><span>{t('common:modals.rematch_modal.played')}</span></th>
          <th><span>{t('common:modals.rematch_modal.wins')}</span></th>
          <th><span>{t('common:modals.rematch_modal.losses')}</span></th>
          {
            gameData.type === 'classic' && gameData.mode === 'team' &&
              <th><span>{t('common:modals.rematch_modal.ranking_avg')}</span></th> 
          }
          {
            gameData.type === 'classic' && gameData.mode === 'solo' &&
             <th><span>{t('common:modals.rematch_modal.ranking')}</span></th>
          }
        </tr>
        {gameData.mode === "team"
          ? Object.values(
              [...gameData.scoreboard].reduce((init, player) => {
                const id = player?.team?.id;
                init[id]
                  ? (init[id] = [...init[id], player])
                  : (init[id] = [player]);
                return init;
              }, {})
            )
              .sort((a, b) => b[0].won - a[0].won)
              .map((player, idx) => {
                return (
                  <tr
                  className={
                    gameData.currentPlayer.team.id == player[idx].teamId ?
                    playerStyles.active : ""
                  }
                   style={{ position: "relative" }} key={idx}>
                    <td className={playerStyles["place-td"]}>
                      {!idx && 
                      <img
                        src={cdnLoader("/images/cup.svg")}
                      />
                      }
                      <div className={playerStyles.place}>{idx + 1}</div>
                    </td>

                    <td>
                      <div
                        className={
                          playerStyles["user-info"] +
                          " " +
                          playerStyles["team-avatars"]
                        }
                      >
                        {player.map((avatar) => (
                          <>
                            <Avatar
                              size="rematch-size"
                              mode="normal"
                              avatarUrls={avatar.player.user.avatar}
                              color={avatar.player.color}
                              hasFlag={false}
                            />
                          </>
                        ))}
                      </div>
                    </td>
                    <td>
                      {player.reduce((accumulator, player) => {
                        return (accumulator += player.totalGames);
                      }, 0)}
                    </td>
                    <td>
                      {player.reduce((accumulator, player) => {
                        return (accumulator += +player.totalWins);
                      }, 0)}
                    </td>
                    <td>
                      {player.reduce((accumulator, player) => {
                        return (accumulator += +player.totalLosses);
                      }, 0)}
                    </td>
                      {gameData.type !== 'private' && (
                         <td className={playerStyles.avg}>
                            {player.reduce((accumulator, player) => {
                              return (accumulator += player.averageRank);
                            }, 0) / player.length}
                          </td>
                      )}
                  </tr>
                );
              })
          : 
            scoreboardSort.map((player, idx) => {
                return (
                  <tr
                    className={
                      player.player.id === gameData.currentPlayer.id
                        ? playerStyles.active
                        : ""
                    }
                    style={{ position: "relative" }}
                    key={idx}
                  >
                    <td className={playerStyles["place-td"]}>
                      {!idx && 
                      <img
                        src={cdnLoader("/images/cup.svg")}
                      />
                      }
                      <div className={playerStyles.place}>{idx + 1}</div>
                    </td>

                    <td>
                      <div className={playerStyles["user-info"]}>
                        <Avatar
                          size="rematch-size"
                          mode="normal"
                          avatarUrls={player.player.user.avatar}
                          color={player.player.color}
                          hasFlag={false}
                        />
                        <p>{player.player.user.username}</p>
                      </div>
                    </td>
                    <td>{player.totalGames}</td>
                    <td>{player.totalWins}</td>
                    <td>{player.totalLosses}</td>
                    {gameData.type !== 'private' && <td className={playerStyles.avg}>{player.averageRank}</td>}
                  </tr>
                );
              })}
      </table>
      {(!!gameData.playersApproved.length || gameData.players.find(player => player.rematchRequested)) && (
        <div className={playerStyles["rematch-request-parent"]}>
        <div className={playerStyles["rematch-request"]}>
          <div className={playerStyles["rematch-request-header"]}>
            <span>{t('common:modals.rematch_modal.rematch')}</span>
          </div>
          <h2 className={playerStyles["rematch-title"]}>
          {(gameData.playersApproved.find(
        (play) => play.id === gameData.currentPlayer.id
      ) ||
        gameData.players.find(
          (player) =>
            player.id === gameData.currentPlayer.id && player.rematchRequested
        ))
          ? t('common:modals.rematch_modal.rematch_requested_text')
          : ""
      }
          </h2>

          <div className={playerStyles["players-container"]}>
            {gameData.players.map((player) => {
              return (
                <div style={{ position: "relative" }}>
                  <Avatar
                    size="rematch-size"
                    mode="normal"
                    avatarUrls={player.user.avatar}
                    hasFlag={false}
                  />

                  <div className={playerStyles.exchange_status}>
                    <div
                      className={playerStyles[player.rematchRequestStatus]}
                    >
                      {(player.rematchRequestStatus === 'ready')  && <img src={cdnLoader("/images/gameModal/check.svg")} />}
                      {(player.rematchRequestStatus === 'declined') && <img src = {cdnLoader("/images/common/close.png")} />}
                      {(player.rematchRequestStatus === 'loading') && <Loader />}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        </div>
      )}

      <div className={playerStyles["button-container"]}>
        {
          (
              gameData.playersApproved.find(
                (play) => play.id === gameData.currentPlayer.id
              )
              || gameData.players.find(
                  (player) => player.id === gameData.currentPlayer.id && player.rematchRequested
              )
              || ! gameData.rematchEnabled
          ) ? (
          <button onClick={() => closeModal()} className={playerStyles.leave}>
            {t('common:buttons.leave')}
          </button>
        ) : (
          <>
            <button onClick={() => closeModal()} className={playerStyles.leave}>
            {t('common:buttons.leave')}
            </button>
            <button
              onClick={() => rematchGame()}
              className={playerStyles.rematch}
            >
              {t('common:buttons.request_rematch')}
            </button>
          </>
        )}
      </div>
    </Modal>
    </>
  );
};
