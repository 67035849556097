import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
// import { logout } from "../../store/auth/authActions";
import Profile from "./Profile/Profile";
import Settings from "./Settings/Settings";
import Accessibility from "./Accessibility/Accessibility";
import GameRules from "./GameRules/GameRules";
import CardsAndValues from "./GameRules/CardsAndValues/CardsAndValues";
import NewLogin from "./NewLogin/NewLogin";
import NewSignUp from "./NewSignUp/NewSignUp";
import Friends from "./Friends/Friends";
import EditProfile from "./EditProfile/EditProfile";
import EditAvatar from "./EditAvatar/EditAvatar";
import { toggleSidebar } from "../../store/modals/modalsActions";
import Overview from "./GameRules/Overview/Overview";
import Principles from "./GameRules/Principles/Principles";
import Versions from "./GameRules/Versions/Versions";
import GameStart from "./GameRules/GameStart/GameStart";
import GameFinish from "./GameRules/GameFinish/GameFinish";
import Exchanging from "./GameRules/Exchanging/Exchanging";
import Overtaking from "./GameRules/Overtaking/Overtaking";
import SendingHome from "./GameRules/SendingHome/SendingHome";
import SpecialRules from "./GameRules/SpecialRules/SpecialRules";
import GameRulesExplanation from './GameRules/GameRules/index';
import NotificationsSidebar from "../Notifications/NotificationsSidebar/NotificationsSidebar";
import InviteFriend from "./InviteFriend/InviteFriend";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import SocialRedirect from "./SocialRedirect/SocialRedirect";
import LobbyGameSetupActions from "./LobbyGameSetupActions/LobbyGameSetupActions";
import MyGames from "../MyGames/MyGames";
import EditTeam from "../EditTeam/EditTeam";
import EditGame from "../EditGame/EditGame";
import GamePageMenu from './GamePageMenu/GamePageMenu';
import ProjectVersions from "./ProjectVersions/ProjectVersions";
import * as styles from "../../styles/scss/components/Sidebar/Sidebar.module.scss";
import { toggleBackdropState } from "../../store/backdropState/backdropStateActions";
import { CloseButton, BackButton } from "../Buttons/Buttons";
import { setChosenAvatar } from "../../store/avatars/avatarsActions";
// import { resetMm } from "../../store/matchMaking/matchMakingActions";
// import { resetSetup } from "../../store/gameSetup/gameSetupActions";
import { setUserForProfileSidebar } from "../../store/modals/modalsActions";
import { useRouter } from "next/router";
import TableFilters from "./TableFilters/TableFilters";
import LobbyHeaderActions from "./LobbyHeaderActions/LobbyHeaderActions";
import SidebarActions from "./SidebarActions/SidebarActions";
import BranediShop from "./BraendiShop/BraendiShop";
import Tutorial from "./Tutorial/Tutorial";
import useTranslation from "next-translate/useTranslation";
import MyGamesFilter from "./MyGamesFilter/MyGamesFilter";
import { deviceType } from "../../helpers/functions";
import { cdnLoader } from "../../config/cdnImageLoader.config";

const Sidebar = (props) => {
  const {
    sidebarType,
    callBackFunc,
    sidebarHasBackArrow,
    callBackSidebarFunction,
    hasTitle,
    isLoggedInUser,
    // onLogout,
    onToggleSidebar,
    onToggleBackdropState,
    onSetChosenAvatar,
    // onResetMM,
    // onResetSetup,
    user,
    onSetUserForProfileSidebar,
    userToShow,
    gameName,
    setupName,
    customTitle,
    showSidebarCloseButton,
    forRules,
    incomingRuleIndex,
  } = props;
  const {t} = useTranslation();
  const router = useRouter();

  const currentLang = useSelector(state => state.languages.currentLang)

  const [touchstartX, setTouchstartX] = useState(0);
  // const [touchstartY, setTouchstartY] = useState(0);
  let touchstartY = 0;
  const [touchendX, setTouchendX] = useState(0);
  // const [touchendY, setTouchendY] = useState(0);
  let touchendY = 0;

  const sidebarRef = useRef()
  const [extended, setIsExtended] = useState(false)

  // useEffect(() => {
  //   if (sidebarRef.current) {
  //     const gestureZone = sidebarRef.current;

  //     gestureZone.addEventListener('touchstart', setTouchStarts);
    
  //     gestureZone.addEventListener('touchend', setTouchEnds);

  //     return (() => {
  //       gestureZone.removeEventListener('touchstart', setTouchStarts)
  //       gestureZone.removeEventListener('touchend', setTouchEnds)
  //     })
  //   }
  // }, [sidebarRef])

  const setTouchStarts = (event) => {
    // setTouchstartX(event.changedTouches[0].screenX);
    // setTouchstartY(event.changedTouches[0].screenY);
    touchstartY = event.changedTouches[0].screenY;
  }
  const setTouchEnds = (event) => {
    // setTouchendX(event.changedTouches[0].screenX);
    // setTouchendY(event.changedTouches[0].screenY);
    touchendY = event.changedTouches[0].screenY
    handleGesture();
  }

  const handleGesture = () => {
    // if (touchendX <= touchstartX) {
    //   alert('Swiped left');
    // }
    
    // if (touchendX >= touchstartX) {
    //   alert('Swiped right');
    // }
    
    if (window.innerWidth <= 768) {
      if (touchendY + 40 <= touchstartY) {
        //swipe up
        // alert('Swiped up');
        setIsExtended(true)
      }
      
      if (touchendY >= touchstartY + 40) {
        //swipe down
        setIsExtended(false)
        // alert('Swiped down');
      }
    }    
    // if (touchendY === touchstartY) {
    //   alert('Tap');
    // }
  }

  const [generalRules, setGeneralRules] = useState([
    {
      id: 1,
      image: "/images/rules/overview.svg",
      rule_text: t('common:sidebar_titles.overview'),
      rule_type: "overview",
    },
    {
      id: 2,
      image: "/images/rules/game-principle.svg",
      rule_text: t('common:sidebar_titles.game_principles'),
      rule_type: "principles",
    },
    {
      id: 3,
      image: "/images/rules/game-versions.svg",
      rule_text: t('common:sidebar_titles.game_versions'),
      rule_type: "versions",
    },
    {
      id: 4,
      image: "/images/rules/cards-and-values.svg",
      rule_text: t('common:sidebar_titles.cards_and_values'),
      rule_type: "cardValues",
    },
    {
      id: 11,
      image: "/images/rules/game_rules.svg",
      rule_text: t('common:sidebar_titles.game_rules'),
      rule_type: "gamerules",
    },
    {
      id: 5,
      image: "/images/rules/start.svg",
      rule_text: t('common:sidebar_titles.start'),
      rule_type: "start",
    },
    {
      id: 6,
      image: "/images/rules/finish.svg",
      rule_text: t('common:sidebar_titles.approaching_finish'),
      rule_type: "finish",
    },
    {
      id: 7,
      image: "/images/rules/exchanging-marbles.svg",
      rule_text: t('common:sidebar_titles.exchanging'),
      rule_type: "exchange",
    },
    {
      id: 8,
      image: "/images/rules/overtaking.svg",
      rule_text: t('common:sidebar_titles.overtaking'),
      rule_type: "overtake",
    },
    {
      id: 9,
      image: "/images/rules/sending-home.svg",
      rule_text: t('common:sidebar_titles.sending_home'),
      rule_type: "home",
    },
    {
      id: 10,
      image: "/images/rules/special-rules.svg",
      rule_text: t('common:sidebar_titles.special_rules'),
      rule_type: "special",
    },
  ]);

  // const handleLogout = () => {
  //   onLogout();
  //   callBackFunc();
  //   onResetMM();
  //   onResetSetup();
  // };

  const [chosenRuleIndex, setChosenRuleIndex] = useState(null);

  const getSidebarTitle = (sidebarType) => {
    switch (sidebarType) {
      case "profile":
        if (isLoggedInUser) {
          return t('common:sidebar_titles.my_profile');
        } else if (userToShow?.isFriend) {
          return t('common:sidebar_titles.friends_profile');
        } else {
          return t('common:sidebar_titles.users_profile');
        }
      case "settings":
        return t('common:sidebar_titles.settings');
      case "accessibility":
        return t('common:sidebar_titles.accessibility');
      case "rules":
        return t('common:sidebar_titles.game_rules');
      case "report":
        return t('common:sidebar_titles.report_bug');
      case "logIn":
        return t('common:sidebar_titles.login_sidebar_header');
      case "signUp":
        return t('common:sidebar_titles.signup_sidebar_header');
      case "friends":
        return t('common:sidebar_titles.friends');
      case "editProfile":
        return t('common:sidebar_titles.edit_profile');
      case "editAvatar":
        return t('common:sidebar_titles.change_avatar');
      case "overview":
        return t('common:sidebar_titles.overview');
      case "cardValues":
        return t('common:sidebar_titles.cards_and_values');
      case "principles":
        return t('common:sidebar_titles.game_principles');
      case "versions":
        return t('common:sidebar_titles.game_versions');
      case "start":
        return t('common:sidebar_titles.start');
      case "finish":
        return t('common:sidebar_titles.approaching_finish');
      case "exchange":
        return t('common:sidebar_titles.exchanging');
      case "overtake":
        return t('common:sidebar_titles.overtaking');
      case "home":
        return t('common:sidebar_titles.sending_home');
      case "special":
        return t('common:sidebar_titles.special_rules');
      case "gamerules":
        return t('common:sidebar_titles.game_rules');
      case "notification":
        return t('common:sidebar_titles.notifications');
      case "inviteFriend":
        return t('common:sidebar_titles.invite_friends');
      case "forgotPassword":
        return t('common:sidebar_titles.forgot_passowrd');
      case "resetPassword":
        return t('common:sidebar_titles.reset_password');
      case "socialRedirect":
        return t('common:sidebar_titles.finish_sign_up');
      case "myGames":
        return t('common:sidebar_titles.my_games');
      case "editTeam":
        return t('common:sidebar_titles.edit_team');
      case "editGame":
        return t('common:sidebar_titles.edit_game_name'); 
      case "leaderboardFilter":
        return t('common:sidebar_titles.filter');
      case "gamePageMenu":
        return t('common:sidebar_titles.menu');
      case "lobbyActions":
        return gameName
      case "barendiShop":
        return t('common:sidebar_titles.braendi_shop'); 
      case "projectVersions":
        return t('common:sidebar_titles.changelog_versions'); 
      case "lobbySetup":
        if (setupName === 'playerNumber') {
          return t('common:sidebar_titles.players');
        } else {
          return t(`common:sidebar_titles.${setupName}`)
        }
      case "myGamesFilter": {
        return t("common:sidebar_titles.my_games_filter_sidebar_title");
      }
      default:
        return "";
    }
  };

  const chooseCustomRule = (ruleIndex) => {
    setChosenRuleIndex(ruleIndex)
    setGeneralRules(generalRules => {
      return generalRules.map((rule, index) => {
        const isRuleActive = index === ruleIndex;
        return {
          ...rule,
          active: isRuleActive,
        };
      });
    });
  };

  useEffect(() => {
    for (let i = 0; i < generalRules.length; i++) {
      if (generalRules[i].active) {
        setChosenRuleIndex(i);
        onToggleSidebar({
          isOpen: true,
          sidebarType: generalRules[i].rule_type,
          hasBackArrow: true,
          hasTitle: true,
          showSidebarCloseButton: false,
          callBackSidebarFunction: () => {
              if(router.pathname.includes('lobby')){
                onToggleSidebar({
                  sidebarType: 'rules',
                  isOpen: true,
                  hasTitle: true,
                  showSidebarCloseButton: true
                })
              } else {
                onToggleSidebar({
                  isOpen: false,
                  sidebarType: ''
                })
              }
          },
        });
        return;
      }
    }
    if(deviceType() !== 'mobile'){
      setChosenRuleIndex(null);
    } 
    else{
      if(chosenRuleIndex > 10){
        setChosenRuleIndex(10);
      } else if(chosenRuleIndex < 0){
        setChosenRuleIndex(0);
      }
    }
  }, [generalRules]);
  
  return (
    <div
      className={`${styles.sidebar} ${sidebarType === 'lobbySetup' && styles.lobbySetup} ${
        chosenRuleIndex !== null || forRules ? styles.nonStandard : ""
      } ${
        extended && styles.extended
      }`}
      ref = {sidebarRef}
    >
      {/* <div className={`${styles.sidebar} ${styles.nonStandard}`}> */}
      <div className={styles.sidebar_content}>
        <div className={styles.sidebar_header}>
          <div className={styles.sidebar_header_cont}>
            {sidebarHasBackArrow && (
              <div
                className={styles.sidebar_back_arrow}
              >
                <BackButton click = {callBackSidebarFunction}/>
              </div>
            )}
            {hasTitle && (
              <h2 className={styles.sidebar_title}>
                {" "}
                {getSidebarTitle(sidebarType)}
              </h2>
            )}
          </div>
          {/* <button className={styles.closeButton} onClick={callBackFunc}>
            <img src={cdnLoader("/images/common/close.png")} />
          </button> */}
          {showSidebarCloseButton && (
            <CloseButton click ={ () => {
                onSetChosenAvatar(null)
                onToggleBackdropState(false)
                callBackFunc()
              }}
            />          
          )}
        </div>
        <div className={styles.sidebar_body}>
          {props.sidebarType === "profile" && <Profile />}
          {props.sidebarType === "settings" && <Settings />}
          {props.sidebarType === "accessibility" && <Accessibility />}
          {props.sidebarType === "rules" && (
            <GameRules
              generalRules={generalRules}
              chooseCustomRule={chooseCustomRule}
            />
          )}
          {props.sidebarType === "cardValues" && <CardsAndValues />}
          {props.sidebarType === "overview" && <Overview />}
          {props.sidebarType === "principles" && <Principles />}
          {props.sidebarType === "versions" && <Versions />}
          {props.sidebarType === "start" && <GameStart />}
          {props.sidebarType === "finish" && <GameFinish />}
          {props.sidebarType === "exchange" && <Exchanging />}
          {props.sidebarType === "overtake" && <Overtaking />}
          {props.sidebarType === "home" && <SendingHome />}
          {props.sidebarType === "special" && <SpecialRules />}
          {props.sidebarType === "logIn" && <NewLogin />}
          {props.sidebarType === "signUp" && <NewSignUp />}
          {props.sidebarType === "friends" && <Friends />}
          {props.sidebarType === "editProfile" && <EditProfile />}
          {props.sidebarType === "editAvatar" && <EditAvatar />}
          {props.sidebarType === "notification" && <NotificationsSidebar />}
          {props.sidebarType === "inviteFriend" && <InviteFriend user={user} toggleSidebar = {onToggleSidebar}/>}
          {props.sidebarType === "forgotPassword" && <ForgotPassword toggleSidebar = {onToggleSidebar} />}
          {props.sidebarType === "resetPassword" && <ResetPassword />}
          {props.sidebarType === "socialRedirect" && <SocialRedirect />}
          {props.sidebarType === "myGames" && <MyGames />}
          {props.sidebarType === "editTeam" && <EditTeam />}
          {props.sidebarType === "editGame" && <EditGame />}
          {props.sidebarType === "barendiShop" && <BranediShop />}
          {props.sidebarType === "sidebarActions" && <SidebarActions />}
          {props.sidebarType === "projectVersions" && <ProjectVersions />}
          {props.sidebarType === "gamerules" && <GameRulesExplanation currentLang = {currentLang} />}
          {props.sidebarType === "tutorial" && <Tutorial 
            chooseCustomRule={chooseCustomRule}
            incomingRuleIndex = {incomingRuleIndex}
          />}
          {props.sidebarType === "lobbySetup" && <LobbyGameSetupActions toggleSidebar = {onToggleSidebar} />}
          {props.sidebarType === "gamePageMenu" && <GamePageMenu
            user = {user}
            openSidebar = {onToggleSidebar}
            onSetUserForProfileSidebar = {onSetUserForProfileSidebar}
          />}
          {props.sidebarType === 'leaderboardFilter' && <TableFilters />}
          {props.sidebarType === 'lobbyActions' && <LobbyHeaderActions />}
          {props.sidebarType === 'myGamesFilter' && <MyGamesFilter/>}
        </div>
      </div>
      {props.sidebarType === 'rules' || props.sidebarType === 'tutorial' || chosenRuleIndex !== null && (
        <div className={styles.sidebar_bottom_side}>
          {props.sidebarType === "rules" && chosenRuleIndex === null && (
            <div className={styles.rules_qr_code}>
              <div className={styles.qr_code_holder}>
                <img src={cdnLoader("/images/sidebar/qr.png")} className={styles.qr_code} />
                <div className={styles.qr_text}>
                  <h4 className={styles.qr_title}>Scan QR Code</h4>
                  <span className={styles.qr_description}>
                    to view rules on mobile
                  </span>
                </div>
              </div>
            </div>
          )}          
          {(
            chosenRuleIndex !== null || 
            props.sidebarType === 'tutorial') && (
            <div className={`${styles.change_rules} ${deviceType() === 'mobile' ? styles.center : undefined}`}>
              { (deviceType() !== 'mobile') && chosenRuleIndex - 1 >= 0 && (
                <div
                  className= {`${styles.previous_rule} ${styles.other_rules}`}
                  onClick={() => {
                      chooseCustomRule(chosenRuleIndex - 1);
                  }}
                >
                  <div className={styles.action_and_text}>
                    <div className={styles.image_holder}>
                      <img src={cdnLoader("/images/sidebar/left.png")} />
                    </div>
                    <span>{t('common:buttons.previous')}</span>
                  </div>
                  <div className={styles.rule_info}>
                    <img src={cdnLoader(`${generalRules[chosenRuleIndex - 1].image}`)} />
                    <span className={styles.rule_info_text}>
                      {generalRules[chosenRuleIndex - 1].rule_text}
                    </span>
                  </div>
                </div>
              )}
              { (deviceType() !== 'mobile') && chosenRuleIndex + 1 <= generalRules.length - 1 && (
                <div
                  className={`${styles.next_rule} ${styles.other_rules}`}
                  onClick={() => {
                      chooseCustomRule(chosenRuleIndex + 1);
                  }}
                >
                  <div className={styles.rule_info}>
                    <img src={cdnLoader(`${generalRules[chosenRuleIndex + 1].image}`)} />
                    <span className={styles.rule_info_text}>
                      {generalRules[chosenRuleIndex + 1].rule_text}
                    </span>
                  </div>
                  <div className={styles.action_and_text}>
                    <span>{t('common:buttons.next')}</span>
                    <div className={styles.image_holder}>
                      <img src={cdnLoader("/images/sidebar/right.png")} />
                    </div>
                  </div>
                </div>
              )}

              {(deviceType() === 'mobile') &&
              ((chosenRuleIndex + 1 <= generalRules.length - 1) || (chosenRuleIndex - 1 >= 0)) && 
              (
                <div
                  className={`${styles.next_rule} ${styles.other_rules}`}
                >
                  <div className={styles.rule_info} onClick={() => {
                    chooseCustomRule(chosenRuleIndex - 1);
                  }}>
                    <img style={{width: 'auto', height: 'auto'}} src={cdnLoader("/images/sidebar/left.png")} />
                    <span tabIndex={-1} className={styles.rule_info_text}>
                      <p>{t('common:buttons.previous')}</p>
                    </span>
                  </div>
                  <div className={styles.action_and_text} onClick={() => {
                    chooseCustomRule(chosenRuleIndex + 1);
                  }}>
                    <span tabIndex={-1}>{t('common:buttons.next')}</span>
                    <div className={styles.image_holder}>
                      <img src={cdnLoader("/images/sidebar/right.png")} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedInUser: state.modals.userToShow
      ? state.modals.userToShow.isLoggedInUser
      : false,
    user: state.auth.user,
    userToShow: state.modals.userToShow,
    gameName: state.modals.sidebarData?.gameName,
    setupName: state.modals.sidebarData?.setupName,
    incomingRuleIndex: state.modals.sidebarData?.activeRuleIndex,
    forRules: state.modals.forRules
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFrinedshipRequest: (credentials) =>
      dispatch(friendshipActions.friendRequest(credentials)),
    // onLogout: () => dispatch(logout()),
    onToggleSidebar: (sidebarData) => dispatch(toggleSidebar(sidebarData)),
    onToggleBackdropState: (state) => dispatch(toggleBackdropState(state)),
    onSetChosenAvatar: (avatar) => dispatch(setChosenAvatar(avatar)),
    // onResetMM: () => dispatch(resetMm()),
    // onResetSetup: () => dispatch(resetSetup()),
    onSetUserForProfileSidebar: (user) => dispatch(setUserForProfileSidebar(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
