import useTranslation from "next-translate/useTranslation";
import * as styles from "../../../../styles/scss/components/CardsAndValues/CardsAndValues.module.scss";
import { cdnLoader } from "../../../../config/cdnImageLoader.config";
const GameStart = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.single_rule_container}>
            <p className={`${styles.standard_paragraph} ${styles.text}`}>
                {t("game_rules:start.first_paragraph")}
            </p>
            <br></br>
            <br></br>
            <div className={`${styles.landscape} ${styles.module_cards_holder}`}>
                <ul
                    className={`${styles.module_cards_ul} ${styles.static_cards}`}
                >
                    <li className={`${styles.module_card_li}`}>
                        <div className={styles.module_card_holder}>
                            <img src={cdnLoader("/images/cards/classic/AH.svg")} />
                        </div>
                    </li>
                    <li className={`${styles.module_card_li}`}>
                        <div className={styles.module_card_holder}>
                            <img src={cdnLoader("/images/cards/classic/KH.svg")} />
                        </div>
                    </li>
                    <li className={`${styles.module_card_li}`}>
                        <div className={styles.module_card_holder}>
                            <img src={cdnLoader("/images/cards/classic/JK.svg")} />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default GameStart;
