import * as styles from '../../styles/scss/components/Loader/Loader.module.scss'
import { cdnLoader } from '../../config/cdnImageLoader.config';

const Loader = () => {
  return (
    <div className={styles.loader_container}>
      <img src={cdnLoader('/images/loader-global.gif')} />
    </div>
  )
}

export default Loader