import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as settingActions from '../../../store/settings/settingsActions';

import Trigger from '../../Trigger/Trigger'
import * as styles from '../../../styles/scss/components/Settings/Settings.module.scss';
import { useRouter } from 'next/router';
import { Dropdown } from '../../FormInput/FormInput';
import { SetLanguagesOnPage } from '../../../store/languages/languagesActions';
import { setShowOnboardingElement } from '../../../store/onBoarding/onBoardingActions';
import useTranslation from 'next-translate/useTranslation';
import { getRouterQuery } from '../../../helpers/GetQueryParams';
import { toggleSidebar } from '../../../store/modals/modalsActions';
import ChevronRigthSvg from '../../../public/images/gamePageButtons/chevron_right.svg'
import { useDispatch, useSelector } from 'react-redux';
import { cdnLoader } from '../../../config/cdnImageLoader.config';
const Settings = (props) => {
  const {
    settings,
    OnToggleBackgroundMusic,
    OnToggleSoundEffect,
    OnToggleAssistMode,
    onSetCardStyle,
    onSetLanguage,
    OnTogglePushNotifications,
    OnToggleEmailNotifications,
    OnToggleOnlineStatus,
    onHandleThemeChange,
    onHandleScreenModeChange,
    onHandleWebpageThemeChange,
    onSaveSettings,
    currentLanguauge,
    onSetLanguagesOnPage,
    onSetShowOnboardingElement
    // onUpdateOnlineStatus
  } = props

  const {t} = useTranslation()

  const dispatch = useDispatch()
  const version = useSelector(state => state.static.version)
  const {locale} = useRouter()
  const saveSettingsHandler = async () => {
    if (!enabled) {
      return
    }

    setEnabled(false)
    setButtonText(t('common:buttons.saving'))
    const payload = {
      backgroundMusic: settings.isBackgroundMusicOn,
      soundEffects: localSettings.isSoundEffectsOn,
      screenMode: settings.screenMode.id,
      screenTheme: settings.screenTheme?.id,
      cardStyle: localSettings.localCardStyle,
      boardTheme: settings.activeBoardTheme.id,
      // languageId: 2,
      asistMode: settings.isAssitModeOn,
      pushNotifications: settings.isPushNotificationsOn,
      hideOnlineStatus: !localSettings.showOnlineStatus,
      emailNotifications: settings.isEmailNotificationsOn,
      language: localSettings.language,
      showTutorial: localSettings.showTutorial
    }
    await onSaveSettings(payload)
    .then(response => {
      const query = getRouterQuery(router)
      router.replace({pathname: `${router.pathname}`, query: query}, null, {locale: localSettings.language})
      setEnabled(false)
      setButtonText(t('common:buttons.save_button'))
      onSetLanguagesOnPage(localSettings.language)
      // if (settings.showOnlineStatus !== !response.data.hideOnlineStatus) {
      //   onUpdateOnlineStatus(!response.data.hideOnlineStatus)
      // }
    }).catch(e => {
      setEnabled(true)
      setButtonText(t('common:buttons.save_button'))
    })
  }


  // useEffect(() => {
  //   const languagesArray = [];
  //   const newLanguages = transformLanguagesArrayIntoUserFriendlyTexts(languagesArray);
  //   setLanguageList(() => [...newLanguages])
  // }, [])

  const [languageList, setLanguageList] = useState([])

  const [isMusicOn, setIsMusicOn] = useState(true);
  const [isSoundEffectsOn, setIsSoundEffectsOn] = useState(false);
  const [cardStyle, setCardStyle] = useState('classic');
  const [assistModeIsOn, setAssistModeIsOn] = useState(false)
  const [tabName, setTabName] = useState ('general');
  const [enabled, setEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [buttonText, setButtonText] = useState(t('common:buttons.save_button'))
  const [localSettings, setLocalSettings] = useState({
    language: 'en',
    isSoundEffectsOn: true,
    showOnlineStatus: true,
    showTutorial: false,
    localCardStyle: 'classic'
  })
  const router = useRouter()

  const [themes, setThemes] = useState(
    [
      {
        id: 'theme1',
        name: 'theme1',
        isActive: true
      },
      {
        id: 'theme2',
        name: 'theme2',
        isActive: false
      },
      {
        id: 'theme3',
        name: 'theme3',
        isActive: false
      },
      {
        id: 'theme4',
        name: 'theme4',
        isActive: false
      }
    ]
  )


  useEffect(() => {
    setLocalSettings(prevState => {
      return{
        ...prevState,
        ...settings,
        language: currentLanguauge,
        localCardStyle: settings.cardStyles.find(cardType => cardType.isActive).id
      }
    })
  }, [])


  const handleModeChange = (mode) => {
    onHandleScreenModeChange(mode)
  }

  const handleThemeChange = (theme) => {
    onHandleThemeChange(theme)
  }

  const handlePageThemeChange = (theme) => {
    onHandleWebpageThemeChange(theme)
  }

  const handleLocalSettingsChange = (settingKey, value) => {
    setLocalSettings(prevState => {
      return {
        ...prevState,
        [settingKey]: value
      }
    })
    setEnabled(true)
  }

  const langs = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    es: 'Español',
    it: 'Italiano',
    ka: 'ქართული',
    ru: 'Русский'
  }

  const getCardSrc = (cardCode, cardType) => {
    if (localSettings.localCardStyle === 'easy') {
      return `/images/cards/easy/${cardCode}.svg`
    } else {
      return `/images/cards/classic/${cardCode + cardType}.svg`
    }    
  }

  const changeLanguage = (lang) => {
    // debugger;
    handleLocalSettingsChange('language', lang.id)
    // setLocalSettings(prevState => {
    //   return {
    //     ...prevState,
    //     language: lang.id
    //   }
    // })
    // router.push('', '', {locale: lang.id})
    // router.push(`/${lang}/${router.asPath}`)
    // router.locale = lang
    // window.location.href = `/${lang}/${router.asPath}`
  }

  const [languages, setLanguages] = useState([])
  useEffect(() => {
    if (!!router.locales) {
      // const languages = router.locales.map(lang => {
      //   return {
      //     value: langs[lang],
      //     id: lang,
      //   }
      // })
      // setLanguages(languages)
      setLanguages([{value: 'English', id: 'en'}, {value: 'Deutsch', id: 'de'}, {value: 'Español', id: 'es'}, {value: 'Français', id: 'fr'}, {value: 'Italiano', id: 'it'}, {value: 'Русский', id: 'ru'}, {value: 'ქართული', id: 'ka'}])
    }
  }, [router.locales])

  return (
    <div className={styles.container}>
    <section className = {styles.settings}>
      <div className = {styles.settings_holder}>
        {tabName === 'general' && 
          <>
            <div className={styles.sectionOne}>
              <div className = {`${styles.single_setting} ${styles.lang}`}>
                <span className = {styles.setting_text}>{t('common:sidebar_body.settings.language')}</span>
                <div className = {styles.languages_container}>
                  <Dropdown 
                    list = {languages}
                    change = {(lang) => {changeLanguage(lang)}}
                    absolute = {true}
                    label = {''}
                    value = {langs[localSettings.language]}
                    small={true}
                    autoHeight = {true}
                  />
                </div>
              </div>
              <div className = {styles.single_setting}>
                <span className = {styles.setting_text}>{t('common:sidebar_body.settings.sound_effects')}</span>
                <div className = {styles.setting_actions}>
                  <Trigger
                    active = {localSettings.isSoundEffectsOn}
                    // clickFunction = {() => {OnToggleSoundEffect(!settings.isSoundEffectsOn)}}
                    clickFunction = {() => {handleLocalSettingsChange('isSoundEffectsOn', !localSettings.isSoundEffectsOn)}}
                  />
                </div>
              </div>
              <div className = {styles.single_setting}>
                <span className = {styles.setting_text}>{t('common:sidebar_body.settings.show_online_status')}</span>
                <div className = {styles.setting_actions}>
                  <Trigger
                    active = {localSettings.showOnlineStatus}
                    // clickFunction = {() => {OnToggleOnlineStatus(!settings.showOnlineStatus)}}
                    clickFunction = {() => {handleLocalSettingsChange('showOnlineStatus', !localSettings.showOnlineStatus)}}
                  />
                </div>
              </div>
              {router.pathname === '/' && (
                <>
                  <div className = {styles.single_setting}>
                    <span className = {styles.setting_text}>{t('common:sidebar_body.settings.see_onboarding_again')}</span>
                    <div className = {styles.setting_actions}>
                      <Trigger
                        active = {localSettings.showTutorial}
                        // clickFunction = {() => {OnToggleOnlineStatus(!settings.showOnlineStatus)}}
                        clickFunction = {() => {handleLocalSettingsChange('showTutorial', !localSettings.showTutorial)}}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.sectionTwo}>  
              <div className = {`${styles.single_setting} ${styles.card_style}`}>
                <span className = {styles.setting_text}>{t('common:sidebar_body.settings.card_style')}</span>
                <div className = {`${styles.setting_actions} ${styles.style_buttons}`}>
                  {settings.cardStyles.map(cardStyle => {
                    return (                    
                      <button
                        className = {localSettings.localCardStyle === cardStyle.id ? styles.active : ''}
                        onClick = { () => {handleLocalSettingsChange('localCardStyle', cardStyle.id)}}
                        key = {`cardStyle${cardStyle.id}`}
                        >
                          <span>
                            {t(`common:sidebar_body.settings.${cardStyle?.name?.toLowerCase()}`)}                          
                          </span>
                      </button>
                    )
                  })}
                </div>
                <div className={styles.card_preview_container}>
                  <div className={styles.card_container}>
                    <img src={cdnLoader(getCardSrc('K', 'H'))} alt = 'card style preview'/>
                  </div>
                  <div className={styles.card_container}>
                    <img src={cdnLoader(getCardSrc('4', 'H'))} alt = 'card style preview'/>
                  </div>
                </div>
              </div>
              <div className={styles.single_setting}>
                <div
                  className={styles.changelog_container} 
                  onClick={() => {
                    dispatch(toggleSidebar({
                      sidebarType: 'projectVersions',
                      isOpen: true,
                      hasTitle: true,
                      showSidebarCloseButton: true
                    }))
                  }}>
                    <div className={styles.left_side}>
                      {t('common:sidebar_titles.changelog_versions')} {' '} {version}
                    </div>
                    <div className={styles.right_side}>
                      <ChevronRigthSvg />
                    </div>
                </div>
              </div>
            </div>
          </>
        }
        {tabName === 'game' && 
          <>
            <div className = {styles.single_setting}>
              <span className = {styles.setting_text}>Background Music</span>
              <div className = {styles.setting_actions}>
                <Trigger
                  active = {settings.isBackgroundMusicOn}
                  clickFunction = {() => {OnToggleBackgroundMusic(!settings.isBackgroundMusicOn)}} 
                />
              </div>
            </div>
            <div className = {styles.single_setting}>
              <span className = {styles.setting_text}>Sound Effects</span>
              <div className = {styles.setting_actions}>
                <Trigger
                  active = {settings.isSoundEffectsOn}
                  clickFunction = {() => {OnToggleSoundEffect(!settings.isSoundEffectsOn)}}
                />
              </div>
            </div>
            <div className = {styles.single_setting}>
              <span className = {styles.setting_text}>Assist Mode</span>
              <div className = {styles.setting_actions}>
                <Trigger
                  active = {settings.isAssitModeOn}
                  clickFunction = {() => {OnToggleAssistMode(!settings.isAssitModeOn)}}
                />
              </div>
            </div>
            <div className = {`${styles.single_setting} ${styles.theme_setting}`}>
              <span className = {styles.setting_text}>Board Theme</span>
              <div className = {`${styles.setting_actions} ${styles.themes}`}>
                <ul className={styles.themes_ul}>
                  {settings.boardThemes.map(theme => {
                    const style = {
                      'backgroundImage' : `url('/images/gamepageBackground/${theme.id}.jpg')`
                    }
                    return (
                      <li 
                        className={`${styles.themes_ul_item} ${theme.isActive ? styles.active : ''}`}
                        key={theme.id} 
                        onClick={() => {handleThemeChange(theme)}}
                        style = {style}
                        >
                        <div className={styles.theme_name}>
                          <input type="radio" id={theme.id} name="theme" checked={theme.isActive}/>
                          <label htmlFor={theme.id}> {theme.name} </label>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </>
        }
      </div>
      <div className={styles.bottom_container}>
        <div
          className={styles.changelog_container} 
          onClick={() => {
            dispatch(toggleSidebar({
              sidebarType: 'projectVersions',
              isOpen: true,
              hasTitle: true,
              showSidebarCloseButton: true
            }))
          }}>
            <div className={styles.left_side}>
              {t('common:sidebar_titles.changelog_versions')} {' '} {version}
            </div>
            <div className={styles.right_side}>
              <ChevronRigthSvg />
            </div>
        </div>

        <div className={styles.save_button_container} style={{backgroundColor: 'white'}}>
          <button
            className={styles.save_button} 
            onClick = {saveSettingsHandler}
            disabled = {loading || !enabled}
            >
              {buttonText}
            </button>
        </div>        
      </div>
    </section>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    settings: state.settings,
    currentLanguauge: state.languages.currentLang
  }
}

const mapDispatchToProps = dispatch => {
  return {
    OnToggleBackgroundMusic: (state) => dispatch(settingActions.toggleBackgroundMusic(state)),
    OnToggleSoundEffect: (state) => dispatch(settingActions.toggleSoundEffect(state)),
    OnToggleAssistMode: (state) => dispatch(settingActions.toggleAssistMode(state)),
    onSetCardStyle: (state) => dispatch(settingActions.setCardStyle(state)),
    onSetLanguage: (state) => dispatch(settingActions.setLanguage(state)),
    OnTogglePushNotifications: (state) => dispatch(settingActions.togglePushNotifications(state)),
    OnToggleEmailNotifications: (state) => dispatch(settingActions.toggleEmailNotifications(state)),
    OnToggleOnlineStatus: (state) => dispatch(settingActions.toggleOnlineStatus(state)),
    onHandleThemeChange: (theme) => dispatch(settingActions.handleThemeChange(theme)),
    onHandleScreenModeChange: (mode) => dispatch(settingActions.handleScreenModeChange(mode)),
    onHandleWebpageThemeChange: (theme) => dispatch(settingActions.handleWebpageThemeChange(theme)),
    onSaveSettings: (payload) => dispatch(settingActions.saveSettings(payload)),
    onSetShowOnboardingElement: (state) => dispatch(setShowOnboardingElement(state)),
    onSetLanguagesOnPage: (lang) => dispatch(SetLanguagesOnPage(lang)),
    // onUpdateOnlineStatus: (state) => dispatch(updateOnlineStatus(state))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Settings)
