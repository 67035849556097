import * as actionTypes from './notificationActionTypes';
import api from '../../axios/api';

const getNotificationsRequest = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_REQUEST
  }
}

const getNotificationsSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    payload: {
      notifications: data.data,
      totalCount: data.totalCount 
    }
  }
}

const getNotificationsFail = (error) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_FAIL,
    payload:{
      error
    }
  }
}

export const getNotifications = (page, perPage = 10) => {

  return async (dispatch) => {
    dispatch(getNotificationsRequest());
    api.get(`/notifications?page=${page}&perPage=${perPage}`)
    .then(response => {
      dispatch(getNotificationsSuccess(response));
    })
    .catch(error =>{
      console.log(error);
      dispatch(getNotificationsFail(error));
    })
  }
}

const markNotificationAsReadRequest = () => {
  return {
    type: actionTypes.MARK_NOTIFICATION_AS_READ_REQUEST
  }
}

const markNotificationAsReadSuccess = (notifications) => {
  return {
    type: actionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS,
    payload: {
      notifications
    }
  }
}

const markNotificationAsReadFail = (error) => {
  return {
    type: actionTypes.MARK_NOTIFICATION_AS_READ_FAIL,
    payload:{
      error
    }
  }
}

export const makeActionSuccess = (notification) => {
  return {
    type: actionTypes.MAKE_ACTION_SUCCESS,
    payload: notification,
  }
}

export const markNotificationAsRead = (payload) => {
  const url = payload.all ? '/notifications/mark-as-read' : `/notifications/mark-as-read/${payload.notificationId}`
  return async (dispatch) => {
    dispatch(markNotificationAsReadRequest());
    api.post(url)
    .then(response => {
      dispatch(markNotificationAsReadSuccess(response.data));
    })
    .catch(error =>{
      console.log(error);
      dispatch(markNotificationAsReadFail(error));
    })
  }
}


export const setNewNotification = (notification) => {
  return {
    type: actionTypes.SET_NEW_NOTIFCATION,
    payload: {
      notification
    }
  }
}

export const changeGameRequestNotificationMessage = (payload) => {
  return {
    type: actionTypes.CHANGE_GAME_REQUEST_NOTIFICATION_MESSAGE,
    payload
  }
}

// export const deleteNotifications = () => {
//   return {
//     type: actionTypes.DELETE_NOTIFICATIONS,
//   }
// }

export const getPagIndex = (payload) => {
  return {
    type: actionTypes.PAG_INDEX,
      payload
  }
}