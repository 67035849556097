import useTranslation from 'next-translate/useTranslation'
import * as styles from '../../../../styles/scss/components/CardsAndValues/CardsAndValues.module.scss'
import { cdnLoader } from '../../../../config/cdnImageLoader.config'
const SpecialRules = () => {
  const {t} = useTranslation();
  
  return (
    <div className={styles.single_rule_container}>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:special_rules.first_paragraph')} 
      </p>
      <br></br>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:special_rules.second_paragraph')}
      </p>
      <br></br>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:special_rules.third_paragraph')}
      </p>
      <br></br>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:special_rules.fourth_paragraph')}
      </p>
      <br></br>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:special_rules.fifth_paragraph')}
      </p>
      <br></br>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:special_rules.sixth_paragraph')}
      </p>
      <br></br>
      <br></br>
      <div className={`${styles.image_container} ${styles.overview_image} ${styles.landscape}`}>
        <img src={cdnLoader('/images/rules/special-rules-sidebar.svg')} />
      </div>
    </div>
  )
}

export default SpecialRules