import * as actionTypes from './settingsActionTypes';

const initialState = {
  language: 'en',
  isPushNotificationsOn: false,
  isEmailNotificationsOn: false,
  showOnlineStatus: false,
  isBackgroundMusicOn: false,
  isSoundEffectsOn: false,
  isAssitModeOn: false,
  showTutorial: false,
  cardStyle: {},
  screenMode: {},
  landingType: 'typeA',
  boardThemes: [],
  activeBoardTheme: {},
  screenModes: [],
  screenThemes: [],
  activeScreenTheme: {},
  cardStyles: [],
  maintenance: false
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANDING_TYPE: {
      return {
        ...state,
        landingType: action.payload.type
      }
    };
    case actionTypes.TOGGLE_BACKGROUND_MUSIC: {
      return {
        ...state,
        isBackgroundMusicOn: action.payload.state
      }
    };
    case actionTypes.TOGGLE_SOUND_EFFECT: {
      return {
        ...state,
        isSoundEffectsOn: action.payload.state
      }
    };
    case actionTypes.TOGGLE_ASSIST_MODE: {
      return {
        ...state,
        isAssitModeOn: action.payload.state
      }
    };
    case actionTypes.SET_CARD_STYLE: {
      const activeCardStyle = {...action.payload.style}
      const cardStyles = [...state.cardStyles.map(cardStyle => {
        return {
          ...cardStyle,
          isActive: cardStyle.id === activeCardStyle.id
        }
      })]
      return {
        ...state,
        cardStyle: activeCardStyle,
        cardStyles: cardStyles
      }
    };
    case actionTypes.SET_LANGUAGE: {
      return {
        ...state,
        language: action.payload.language
      }
    };
    case actionTypes.TOGGLE_PUSH_NOTIFICATIONS: {
      return {
        ...state,
        isPushNotificationsOn: action.payload.state
      }
    };
    case actionTypes.TOGGLE_EMAIL_NOTIFICATIONS: {
      return {
        ...state,
        isEmailNotificationsOn: action.payload.state
      }
    };
    case actionTypes.TOGGLE_ONLINE_STATUS: {
      return {
        ...state,
        showOnlineStatus: action.payload.state
      }
    };
    case actionTypes.SET_THEME: {
      let activeBoardTheme = null
      const boardThemes = [...state.boardThemes.map(theme => {
        const active = theme.id === action.payload.theme.id
        if (active) {
          activeBoardTheme = {
            ...theme
          }
        }
        return {
          ...theme,
          isActive: active
        }
      })]
      return {
        ...state,
        boardThemes: boardThemes,
        activeBoardTheme
      }
    };
    case actionTypes.SET_WEBPAGE_THEME: {
      let activeScreenTheme = {...action.payload.theme}
      const screenThemes = [...state.screenThemes.map(screenTheme => {
        return {
          ...screenTheme,
          isActive: activeScreenTheme.id === screenTheme.id
        }
      })]
      return {
        ...state,
        screenThemes: screenThemes
      }
    }
    case actionTypes.SET_SCREEN_MODE: {
      const activeScreenMode = {...action.payload.mode}
      const screenModes = [...state.screenModes.map(mode => {
        return {
          ...mode,
          isActive: mode.id === activeScreenMode.id
        }
      })]
      return {
        ...state,
        screenModes,
        screenMode: activeScreenMode
      }
    };
    case actionTypes.GET_SETTINGS_REQUEST: {
      return {
        ...state
      }
    };
    case actionTypes.GET_SETTINGS_SUCCESS: {
      // debugger;
      const {settings, enums} =  action.payload.settings;
      let activeBoardTheme = {}
      let activeCardStyle = {}
      let activeScreenMode = {}
      const boardThemes = [...enums.boardTheme.map(boardTheme => {
        const active = boardTheme.value === settings.boardTheme;
        if (active) {
          activeBoardTheme = {
            id: boardTheme.value,
            name: boardTheme.label,
            isActive: active  
          }
        }
        return {
          id: boardTheme.value,
          name: boardTheme.label,
          isActive: active
        }
      })];
      const screenModes = [...enums.screenMode.map(screenMode => {
        const active = screenMode.value === settings.screenMode
        if (active) {
          activeScreenMode = {
            id: screenMode.value,
            name: screenMode.label,
            isActive: active  
          }
        }
        return {
          id: screenMode.value,
          name: screenMode.label,
          isActive: active
        }
      })];
      const screenThemes = [...enums.screenTheme.map(screenTheme => {
        return {
          id: screenTheme.value,
          name: screenTheme.label,
          isActive: screenTheme.value === settings.screenTheme
        }
      })];
      const cardStyles = [...enums.cardStyle.map(cardStyle => {
        const active = cardStyle.value === settings.cardStyle;
        if (active) {
          activeCardStyle = {
            id: cardStyle.value,
            name: cardStyle.label,
            isActive: active           
          }
        }
        return {
          id: cardStyle.value,
          name: cardStyle.label,
          isActive: active
        }
      })];
      // debugger;
      return {
        ...state,
        isPushNotificationsOn: settings.pushNotifications,
        isEmailNotificationsOn: settings.emailNotifications,
        showOnlineStatus: !settings.hideOnlineStatus,
        isBackgroundMusicOn: settings.backgroundMusic,
        isSoundEffectsOn: settings.soundEffects,
        isAssitModeOn: settings.assistMode,
        cardStyle: activeCardStyle,
        boardThemes: boardThemes,
        activeBoardTheme: activeBoardTheme,
        screenModes: screenModes,
        screenThemes: screenThemes,
        cardStyles: cardStyles,
        screenMode: activeScreenMode,
        maintenance: settings.maintenanceMode,
        showCardStyleSelectorModal: settings.showCardStyleSelectorModal
      }
    };
    case actionTypes.GET_SETTINGS_FAIL : {
      return {
        ...state
      }
    };
    case actionTypes.SAVE_SETTINGS_REQUEST: {
      return {
        ...state
      }
    };
    case actionTypes.SAVE_SETTINGS_SUCCESS: {
      let activeCardStyle = {}
      const newCardStyles = [...state.cardStyles.map(cardStyle => {
        const active = cardStyle.id === action.payload.settings.cardStyle
        if (active) {
          activeCardStyle = {
            id: cardStyle.id,
            name: cardStyle.name,
            isActive: active
          }
        }
        return {
          ...cardStyle,
          isActive: active
        }
      })]
      return {
        ...state,
        isSoundEffectsOn: action.payload.settings.soundEffects,
        showOnlineStatus: !action.payload.settings.hideOnlineStatus,
        language: action.payload.settings.language,
        showTutorial: action.payload.settings.showOnboarding,
        cardStyles: [...newCardStyles],
        cardStyle: activeCardStyle
      }
    };
    case actionTypes.SAVE_SETTINGS_FAIL: {
      return {
        ...state
      }
    };
    case actionTypes.SET_MEINTANACE_MODE: {
      // debugger;
      return {
        ...state,
        maintenance: action.payload.state
      }
    }
    default:
      return state;
    }
}

export default settingsReducer;