import useTranslation from 'next-translate/useTranslation'
import * as styles from '../../../../styles/scss/components/CardsAndValues/CardsAndValues.module.scss'
import { cdnLoader } from '../../../../config/cdnImageLoader.config'
const Exchanging = () => {
  const {t} = useTranslation();
  return (
    <div className={styles.single_rule_container}>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:exchanging.first_paragraph')}
      </p>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:exchanging.second_paragraph')}
      </p>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:exchanging.third_paragraph')}
      </p>
      <div className = {`${styles.landscape} ${styles.module_cards_holder}`} >
        <ul className = {`${styles.module_cards_ul} ${styles.exchanging}`}>
          <li
            className = {`${styles.module_card_li}`}
            >
            <div className = {styles.module_card_holder}>
              <img src = {cdnLoader('/images/cards/classic/JH.svg')} />
            </div>
          </li>
          <li
            className = {`${styles.module_card_li}`} style={{width: '130px'}}
            >
            <div className = {styles.module_card_holder} style = {{width: '130px'}}>
              <img src = {cdnLoader('/images/rules/exchanging-sidebar.svg')} />
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Exchanging