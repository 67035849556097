import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as styles from '../../styles/scss/components/Header/Header.module.scss';
import Avatar from '../Avatar/Avatar';
import { setUserForProfileSidebar } from '../../store/modals/modalsActions';
import { toggleResponsivePopUp, toggleSidebar } from '../../store/modals/modalsActions';
import {resetSetup} from '../../store/gameSetup/gameSetupActions';
import {resetMm} from '../../store/matchMaking/matchMakingActions';
import { logout } from '../../store/auth/authActions';
import UnAuthorizedHamburger from './unAuthorizedHamburger';
import { handleReturnAsAdmin } from '../../helpers/Impersonate';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { clearFriendsList } from '../../store/friends/friendActions';
import {ButtonRuby} from "../Buttons/ButtonRuby";
import { cdnLoader } from '../../config/cdnImageLoader.config';

const ResponsiveMenu = (props) => {
  const {
    user, 
    isAuthenticated, 
    onToggleSidebar, 
    onSetUserForProfileSidebar,
    unreadNotificationCount,
    onToggleResponsivePopUp,
    openSidebar,
    closePopUp,
    onClearFriendsList
  } = props
  const router = useRouter();
  const dispatch = useDispatch();
  const {t} = useTranslation()
  useEffect(() => {
    if (isAuthenticated) {
      onToggleSidebar({isOpen: false})
    }
  }, [isAuthenticated])

  const handleProfileSidebarToggle = () => {
    onSetUserForProfileSidebar({
      ...user,
      isLoggedInUser: true
    })
    onToggleResponsivePopUp({
      isOpen: false
    })
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'profile',
      hasTitle: true,
      customTitle: 'profile',
      showSidebarCloseButton: true
    })
  }

  const handleInviteFriendsSidebarToggle = () => {
    onToggleResponsivePopUp({
      isOpen: false
    })
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'inviteFriend',
      hasTitle: true,
      customTitle: 'invite friend',
      showSidebarCloseButton: true
    })
  }

  const handleSettingsSidebarToggle = () => {
    onToggleResponsivePopUp({
      isOpen: false
    })
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'settings',
      hasTitle: true,
      customTitle: 'settings',
      showSidebarCloseButton: true
    })
  }

  const handleNotificationsSidebarToggle = () => {
    onToggleResponsivePopUp({
      isOpen: false
    })
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'notification',
      hasTitle: true,
      customTitle: 'notifications',
      showSidebarCloseButton: true
    })
  }

  const handleLogout = () => {
    dispatch(resetMm()),
    dispatch(resetSetup()),
    dispatch(logout()),
    onClearFriendsList()
    closePopUp()
  }

  const handleRedirectToTutorial = () => {
    onToggleResponsivePopUp({
      isOpen: false
    })
    router.push('/how-to-play');
  }

  const handleInspectorClick = async () => {
    if (user.hasAnyRole(['admin', 'superadmin']) && !localStorage.getItem('impersonate_token')) {
      router.push('/inspector')
      closePopUp()
    } else {
      const response = await handleReturnAsAdmin()
      if (response) {
        window.location.href = '/'
      }
    }
  }

  return (
    <nav className = {styles.responsive_navigation}>
      {isAuthenticated ? (
        <>
          {/* <ul className = {styles.navigation_ul}> */}
          <ul className = {`${styles.navigation_ul} ${styles.scrollBar}`}>
            <li className = {styles.navigation_li}   onClick = {handleProfileSidebarToggle}>
              <div className = {styles.navigation_main_cont}>
                <div className = {`${styles.navigation_image_container} ${styles.resp_avatar_cont}`}>
                    <Avatar
                      hasFlag = {false}
                      size = 'small'
                      mode = 'normal'
                      avatarUrls = {{...user.avatar}}
                    />
                </div>
                <h2 className = {styles.navigation_text}>
                  {t('common:sidebar_titles.my_profile')}
                </h2>
              </div>
              <div className = {styles.navigation_indicator}></div>
            </li>

            {(user.hasAnyRole(['admin', 'superadmin'])) && (
                <ButtonRuby responsive={true} />
            )}

            <li className = {styles.navigation_li} onClick = {handleNotificationsSidebarToggle}>
              <div className = {styles.navigation_main_cont}>
                <div className = {styles.navigation_image_container}>
                  <img src = {cdnLoader('/images/common/notification.svg')} />
                </div>
                <h2 className = {styles.navigation_text}>
                  {t('common:sidebar_titles.notifications')}
                </h2>
              </div>
              <div className = {styles.navigation_indicator}>
                {unreadNotificationCount > 0 && <span className = {styles.unread_indicator}>{unreadNotificationCount}</span>}
              </div>
            </li>
            <li className = {styles.navigation_li} onClick = {handleInviteFriendsSidebarToggle}>
              <div className = {styles.navigation_main_cont}>
                <div className = {styles.navigation_image_container}>
                  <img src = {cdnLoader('/images/common/inviteFriend.svg')} />
                </div>
                <h2 className = {styles.navigation_text}>
                  {t('common:sidebar_titles.invite_friends')}
                </h2>
              </div>
            </li>
            <li className = {styles.navigation_li} onClick = {handleRedirectToTutorial}>
              <div className = {styles.navigation_main_cont}>
                <div className = {styles.navigation_image_container}>
                  <img src = {cdnLoader('/images/common/tutorialIcon.svg')} />
                </div>
                <h2 className = {styles.navigation_text}>                
                  {t('navigation:tutorial')}
                </h2>
              </div>
              <div className = {styles.navigation_indicator}></div>
            </li>
            <li className = {styles.navigation_li}  onClick = {handleSettingsSidebarToggle}>
              <div className = {styles.navigation_main_cont}>
                <div className = {styles.navigation_image_container}>
                  <img src = {cdnLoader('/images/common/settings.svg')} />
                </div>
                <h2 className = {styles.navigation_text}>
                  {t('common:sidebar_titles.settings')}
                </h2>
              </div>
              <div className = {styles.navigation_indicator}></div>
            </li>
            {(user.hasAnyRole(['admin', 'superadmin']) || (typeof window !== 'undefined' && localStorage.getItem('impersonate_token'))) && (
              <>
                {user.hasAnyRole(['admin', 'superadmin']) && (
                  <>
                    <li className = {styles.navigation_li}  onClick = {handleInspectorClick}>
                      <div className = {styles.navigation_main_cont}>
                        <div className = {styles.navigation_image_container}>
                          <img src = {cdnLoader('/images/common/incognito.svg')} />
                        </div>
                        <h2 className = {styles.navigation_text}>
                          {t('navigation:inspector')}
                        </h2>
                      </div>
                      <div className = {styles.navigation_indicator}></div>
                    </li>
                  </>
                )}
                {localStorage.getItem('impersonate_token') && (
                  <li className = {styles.navigation_li}  onClick = {handleInspectorClick}>
                    <div className = {styles.navigation_main_cont}>
                      <div className = {styles.navigation_image_container}>
                        <img src = {cdnLoader('/images/common/home.svg')} />
                      </div>
                      <h2 className = {styles.navigation_text}>
                        {t('navigation:back_as_admin')}
                      </h2>
                    </div>
                  </li>
                )}
              </>
            )}
          </ul>
          <div className = {styles.logout_button_container}>
            <button
              className={styles.logout_button}
              onClick={handleLogout}
              >
              {t('common:buttons.logout_button')}
            </button>
          </div>
        </>
        ) : 
        (
          <>
            <UnAuthorizedHamburger 
              openSidebar = {onToggleSidebar}
              closePopUp = {closePopUp}
            />
          </>
        )
      }

    </nav>
   );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    unreadNotificationCount: state.notifications.unreadNotificationCount,
    privateLobby: state.privateLobby
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetUserForProfileSidebar: (user) => dispatch(setUserForProfileSidebar(user)),
    onToggleSidebar: (payload) => dispatch(toggleSidebar(payload)),
    onToggleResponsivePopUp: (payload) => dispatch(toggleResponsivePopUp(payload)),
    onClearFriendsList: (payload) => dispatch(clearFriendsList(payload))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveMenu);