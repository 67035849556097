import { BackButton, EditButton, DeleteButton, LeaveButton, ShareButton, GradientButtons } from "../Buttons/Buttons";
import PopUpModal from '../PopUpModal/PopUpModal';
import * as styles from '../../styles/scss/components/Header/Header.module.scss';
import { useRef, useState, useEffect } from "react";
import TextTooltip from '../Tooltip/TextTooltip';
import { CSSTransition } from "react-transition-group";
import api from '../../axios/api';
import useTranslation from "next-translate/useTranslation";
import Backdrop from "../Backdrop/Backdrop";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { deviceType } from "../../helpers/functions";
import { cdnLoader } from "../../config/cdnImageLoader.config";

const LobbyHeader = (props) => {
  const {
    router, 
    privateLobby, 
    user, 
    onDeleteLobby, 
    socketId, 
    OnToggleConfirmModal, 
    onChangeLobbyName,
    openSidebar
  } = props

  const [showGameNameChangeModal, setShowGameNameChangeModal] = useState(false)
  const [showToolTip, setShowTooltip] = useState(false)
  const [showLobbyDeleteModal, setShowLobbyDeleteModal] = useState(false)
  const [confirmModalData, setConfirmModalData] = useState({})
  const [disableAction, setDisableAction] = useState(false)
  const {t} = useTranslation()
  const linkInputRef = useRef(null)

  const handleGameNameChange = () => {
    if (document.body.offsetWidth > 1023) {
      setShowGameNameChangeModal(prevState => !prevState)
    } else {
      openSidebar({
        sidebarType: 'editGame',
        hasTitle: true,
        isOpen: true,
        showSidebarCloseButton: true,
        sidebarData: {
          saveEditFunction: (e, teamData) => {
            handleSaveGameName(e, teamData)
            openSidebar({
              isOpen: false,
              sidebarType: null
            })
          },
          gameName: privateLobby.name,
        }
      })
    }
  }

  const handleGameDelete = () => {
    if (user.id === privateLobby.host.id) {
      setShowLobbyDeleteModal(true)
      setConfirmModalData({
        text: t('common:modals.delete_game_modal.delete_game_title'),
        acceptText: t('common:buttons.cancel'),
        rejectText: t('common:buttons.delete'),
        closeFunc: () => {
          setShowLobbyDeleteModal(false);
          setDisableAction(false);
        },
        acceptFunc: () => {
          setShowLobbyDeleteModal(false);
          setDisableAction(false);
        },
        rejectFunc: () => {deleteLobbyHandler()}  
      })
      // OnToggleConfirmModal({
      //   isOpen: true,
      //   confirmModalData: {
      //     text: t('common:modals.delete_game_modal.delete_game_title'),
      //     acceptText: t('common:buttons.cancel'),
      //     rejectText: t('common:buttons.delete'),
      //     closeFunc: () => {OnToggleConfirmModal({isOpen: false})},
      //     acceptFunc: () => {OnToggleConfirmModal({isOpen: false})},
      //     rejectFunc: () => {deleteLobbyHandler()}  
      //   }
      // })
    } else {
      setShowLobbyDeleteModal(true)

      setConfirmModalData({
        text: t('common:modals.leave_game_title.leave_game_title'),
        acceptText: t('common:buttons.cancel'),
        rejectText: t('common:buttons.leave'),
        closeFunc: () => {
          setShowLobbyDeleteModal(false);
          setDisableAction(false);
        },
        acceptFunc: () => {
          setShowLobbyDeleteModal(false);
          setDisableAction(false);
        },
        rejectFunc: () => {
          router.push('/');
          setShowLobbyDeleteModal(false);
        }
      })
      // OnToggleConfirmModal({
      //   isOpen: true,
      //   confirmModalData: {
      //     text: t('common:modals.leave_game_title.leave_game_title'),
      //     acceptText: t('common:buttons.cancel'),
      //     rejectText: t('common:buttons.leave'),
      //     closeFunc: () => {OnToggleConfirmModal({isOpen: false})},
      //     acceptFunc: () => {OnToggleConfirmModal({isOpen: false})},
      //     rejectFunc: () => {router.push('/');OnToggleConfirmModal({isOpen: false})}
      //   }
      // })
    }
  }

  const handleSaveGameName = (e,teamData) => {
    e.preventDefault();
    api.put(`/lobby/${router.query.lobbyId}`, {
      name: teamData.name
    })
    .then(response => {
      onChangeLobbyName(response.data.name)
      setShowGameNameChangeModal(false)
    }).catch(e => {
      console.log(e)
    })
  }

  const deleteLobbyHandler = async () => {
    setDisableAction(true)
    const response = await onDeleteLobby(router.query.lobbyId, socketId)
    if (response === true) {
      // OnToggleConfirmModal({isOpen: false})
      setConfirmModalData({})
      setShowLobbyDeleteModal(false)
      router.replace('/')
    }
  }

  useEffect(() => {
    if (showToolTip) {
      const interval = setInterval(() => {
        setShowTooltip(false)
        clearInterval(interval)
      }, 1000)
    }
  }, [showToolTip])

  const hanleLobbyLeave = () => {
    if (user.id === privateLobby.host.id) {
      OnToggleConfirmModal({
        isOpen: true,
        confirmModalData: {
          text: 'If you want to leave lobby, please delete it first',
          acceptText: 'Back to Lobby',
          rejectText: 'delete',
          closeFunc: () => {OnToggleConfirmModal({isOpen: false})},
          acceptFunc: () => {OnToggleConfirmModal({isOpen: false})},
          rejectFunc: () => {deleteLobbyHandler()}  
        }
      })
    } else {
      router.push('/')
    }
  }

  return (
    <div className = {styles.lobby_name_container}>
      {/* <div className = {`${styles.button_container} ${styles.go_back}`}>
        <BackButton click = {() => {hanleLobbyLeave()}}/>
      </div>  */}
      <div className = {styles.lobby_info}>
        <div className = {styles.lobby_type_image_container}>
          <img src = {cdnLoader(`/images/rules/${privateLobby.scheduledAt ? 'scheduled':'instant'}.svg`)} />
        </div>
        <h3 className = {styles.lobby_name}>{privateLobby.name}</h3>
      </div>
      {privateLobby?.host?.id === user?.id && (
        <div className = {`${styles.button_container} ${styles.edit_game_name}`}>
          <EditButton click = {handleGameNameChange}/>
          {showGameNameChangeModal && (
            <div className = {styles.editGame_container}>
              <PopUpModal 
                popupType = 'editGameName'
                gameName = {privateLobby.name}
                saveEditFunction = {handleSaveGameName}
                closeButtonFunc = {() => {setShowGameNameChangeModal(false)}}
              />
            </div>
          )}
        </div> 
      )}
        <div className = {`${styles.button_container} ${styles.red_buttons}`}>
          {privateLobby?.host?.id === user?.id ? (
            <DeleteButton click = {handleGameDelete} />
          ) : (<LeaveButton click = {handleGameDelete} />)
          }
        </div>
      <div className= {`${styles.button_container} ${styles.share_button}`}>
      <input className = {styles.lobby_link} ref = {linkInputRef} type = 'text' value = {window.location.href} onChange = {() => {}}/>
      <ShareButton 
        click = {() => {
          linkInputRef.current.select()
          document.execCommand('copy')
          linkInputRef.current.blur()
          setShowTooltip(true)
        }}
      />
      <CSSTransition
        in = {showToolTip}
        timeout = {500}
        unmountOnExit
        classNames = 'tooltip'
        >
        <div className = {styles.tooltip_container}>
          <TextTooltip 
            text = {t('common:copied')}
            position = {'bottom'}
          />
        </div>
      </CSSTransition>
    </div>

      <div className = {`${styles.button_container} ${styles.details_button}`} 
        onClick = {() => {
          openSidebar({
            sidebarType: 'lobbyActions',
            hasTitle: true,
            isOpen: true,
            showSidebarCloseButton: true,
            sidebarData: {
              saveEditFunction: (e, teamData) => {
                handleSaveGameName(e, teamData)
                openSidebar({
                  isOpen: false,
                  sidebarType: null
                })
              },
              deleteLobbyHandler: () => {deleteLobbyHandler()},
              leaveGameHandler: () => {router.push('/')},
              // handleDeleteGame: () => {
              //   OnToggleConfirmModal({
              //     isOpen: true,
              //     confirmModalData: {
              //       text: 'Are you sure you want to delete the Game?',
              //       acceptText: 'cancel',
              //       rejectText: 'delete',
              //       closeFunc: () => {OnToggleConfirmModal({isOpen: false})},
              //       acceptFunc: () => {OnToggleConfirmModal({isOpen: false})},
              //       rejectFunc: () => {deleteLobbyHandler()}  
              //     }
              //   })
              // },
              // handleLeaveGame: () => {
              //   OnToggleConfirmModal({
              //     isOpen: true,
              //     confirmModalData: {
              //       text: 'Are you sure you want to leave the Game?',
              //       acceptText: 'cancel',
              //       rejectText: 'leave',
              //       closeFunc: () => {OnToggleConfirmModal({isOpen: false})},
              //       acceptFunc: () => {OnToggleConfirmModal({isOpen: false})},
              //       rejectFunc: () => {deleteLobbyHandler()}
              //     }
              //   })
              // },
              showEditButton: privateLobby?.host?.id === user?.id,
              showDeleteLobbyButton: privateLobby?.host?.id === user?.id,
              gameName: privateLobby.name,
            }
          })
        }}
        >
        <GradientButtons
          small = {'small'}
          >
          <div className = {styles.dots}>
            <span className = {styles.dot}></span>
            <span className = {styles.dot}></span>
            <span className = {styles.dot}></span>
          </div>
        </GradientButtons>
      </div>
      {showLobbyDeleteModal && (
        <>
          <Backdrop closeFunc={() => {setShowLobbyDeleteModal(false)}} />
          <div className={styles.confirm_modal_container}>
            <ConfirmModal 
                text = {confirmModalData.text}
                acceptText = {confirmModalData.acceptText}
                rejectText = {confirmModalData.rejectText}
                closeFunc = {confirmModalData.closeFunc}
                acceptFunc = {confirmModalData.acceptFunc}
                rejectFunc = {confirmModalData.rejectFunc}
                actionDisabled = {disableAction}
            />
          </div>
        </>

      )}
    </div>
  )
}

export default LobbyHeader