import React, { useState } from 'react'
import * as styles from '../../styles/scss/components/Modal/Modal.module.scss'
import { connect } from 'react-redux';
import { toggleBackdropState } from '../../store/backdropState/backdropStateActions';
import { CloseButton, MinimizeButton } from '../Buttons/Buttons';
import { cdnLoader } from "../../config/cdnImageLoader.config";

const Modal = (props) => {
  const {
    closeable,
    closeModal,
    title,
    onToggleBackdropState,
    hasModalHeadImage,
    gameType,
    children,
    header = true
  } = props

  const [minimized, setIsMinimized] = useState(false)

  const handleButtonClick = () => {
    if (closeable) {
      closeModal()
      onToggleBackdropState(false)
    } else {
      setIsMinimized(prevState => !prevState)
    }
  }
  
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { minimized });
    }
    return child;
  });


  return (
    <div className={styles.modal_holder_parent}>
      <div className = {`${props.additionalClass ? props.additionalClass : ''} ${styles.modal_holder} ${minimized ? styles.minimized : ''}`}>
        {hasModalHeadImage && !minimized && (
          <div className = {`${styles.modal_head_image} ${styles[gameType]}`}>
            <img src = {`/images/dashboard/${gameType}_bottom.svg`} />
          </div>
        )}
        {!!header && <div className = {styles.modal_header}>
          {hasModalHeadImage && !minimized && (
            <div className = {`${styles.responsive_avatar} ${styles[gameType]}`}>
              <img src = {cdnLoader(`/images/dashboard/${gameType}_bottom.svg`)} />
            </div>
          )}
          {!minimized && <h2 className = {styles.modal_title}>{title}</h2>}
          {closeable && <CloseButton click = {handleButtonClick} />}
          {!closeable && <MinimizeButton click = {handleButtonClick} state = {minimized}/>}
          {/* <button type="button" onClick={() => {
                handleButtonClick()
              }}
            >
              <img alt = 'modal action button' src = {cdnLoader(`/images/common/${closeable || minimized ? 'close' : 'minimize'}.png`)}/>
              
          </button> */}
        </div> }
        {childrenWithProps}
        {/* {children} */}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onToggleBackdropState: (state) => dispatch(toggleBackdropState(state))
  }
}
 
export default connect(null, mapDispatchToProps)(Modal);
