import * as styles from '../../styles/scss/components/GameSearchingStateModals/index.module.scss';
import Loader from '../../components/CustomLoader/Loader';
import CheckSvg from '../../public/images/common/bigCheck.svg'
import WarningSvg from '../../public/images/common/warning.svg'
import { CSSTransition } from 'react-transition-group';
import useTranslation from 'next-translate/useTranslation';
import { cdnLoader } from '../../config/cdnImageLoader.config';
const SearchingBody = (props) => {
  const {
    gameMode,
    playerNumber,
    rules,
    versus,
    type,
    minimized,
    currentlySearchingPLayersNumber
  } = props

  // useEffect(() => {
  //   console.log(currentlySearchingPLayersNumber[gameMode.toLowerCase()])
  // })

  const {t} = useTranslation()
  const renderPlayerNumber = () => {
    if (isNaN(playerNumber) || playerNumber == 0) {
      return t('wizard:online_game.any')
    }
    return playerNumber
  }
  return (
    <div className = {styles.search_progress}>
      <CSSTransition
        in = {!minimized}
        timeout = {minimized ? 1 : 500}
        // apperar = {true}
        unmountOnExit
        classNames = 'modal_header'
      >
        <div className = {styles.game_information}>
          <div className = {styles.single_info_container}>
            <div className = {styles.single_info_title}>{t('common:modals.game_search.mode')}</div>
            <div className = {styles.single_info_details}>{t(`wizard:online_game.${gameMode.toLowerCase()}`)}</div>
          </div>
          <div className = {styles.single_info_container}>
            <div className = {styles.single_info_title}>{t('common:modals.game_search.players')}</div>
            <div className = {styles.single_info_details}>{renderPlayerNumber()}</div>
          </div>
          {(playerNumber === 6 && !!versus) && (
            <div className = {styles.single_info_container}>
              <div className = {styles.single_info_title}>{t('common:modals.game_search.teams')}</div>
              <div className = {styles.single_info_details}>{versus}</div>
            </div>
          )}
          <div className = {styles.single_info_container}>
            <div className = {styles.single_info_title}>{t('common:modals.game_search.rules')}</div>
            <div className = {styles.single_info_list}>
              <ul className = {styles.list_items_ul}>
                {/* {rules.length === 1 && rules[0].symbol !== 'any' && ( */}
                  <li className = {styles.list_item}>
                    <div className = {styles.list_item_container}>
                      <img src = {cdnLoader(`/images/rules/sbd.svg`)} />
                    </div>
                  </li>
                {/* )} */}
                {rules.map(rule => {
                  if (rules.length > 1 && rule.symbol === 'any') return
                  //in solo mode we should not dispaly cs and ccs
                  if(gameMode.toLowerCase() === 'solo' && (rule.symbol === 'cs' || rule.symbol === 'ccs')) return
                  return (
                    <li className = {styles.list_item} key = {`rules${rule.symbol}`}>
                      <div className = {styles.list_item_container}>
                        <img src = {cdnLoader(`/images/rules/${rule.symbol}.svg`)} />
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </CSSTransition>
      <div className = {styles.search_progress_information_container}>
        <div className = {styles.search_progress_information}>
          <div className = {styles.serch_dog_image_container}>
            <img className = {styles.search_dog_image} src={cdnLoader('/images/common/dog.svg')} />
          </div>
          <div className={styles.searching_progress_info_container}>
            <div className = {`${styles.search_progress_text_container} ${styles[type]}`}>
              <div className = {styles.search_progress_graphic}>
                {type === 'searchingGame' && (
                  <Loader />
                )}
                {type === 'gameFound' && (
                  <div className = {styles.game_found}>
                    <CheckSvg />
                  </div>
                )}
                {(type === 'gameCouldNotStart' || type === 'ongoingGame') && (
                  <div className = {`${styles.game_found} ${styles.could_not_start}`}>
                    <WarningSvg />
                  </div>
                )}
              </div>
              <div className = {styles.search_progress_text}>
                <h3 className = {styles.text_top}>
                  {type === 'searchingGame' && t('common:modals.game_search.searching_text')}
                  {type === 'gameFound' && t('common:modals.game_search.game_found_text')}
                  {type === 'gameCouldNotStart' && t('common:modals.game_search.other_players_dissconected')}
                  {type === 'ongoingGame' && t('common:modals.game_search.public_game_in_progress')}
                </h3>
                <h4 className = {styles.bottom_text}>
                  {type === 'searchingGame' && t('common:modals.game_search.please_wait')}
                  {type === 'gameFound' && t('common:modals.game_search.connecting')}
                  {type === 'gameCouldNotStart' && t('common:modals.game_search.please_search_again')}
                  {type === 'ongoingGame' && t('common:modals.game_search.other_players_are_waiting_for_you')}
                </h4>
              </div>
            </div>
            {type === 'searchingGame' && <>
              <div className = {styles.searching_progress_players}>
                <div className = {styles.icon_container}>
                  <img src = {cdnLoader('/images/common/players-icon.svg')} alt='searching icon' />
                </div>
                <div className = {`${styles.numbers_container} ${currentlySearchingPLayersNumber[gameMode.toLowerCase()] == 0 ? styles.disabled : ''}`}>
                  <span className = {`${styles.player_number} ${styles.current_number}`}>{currentlySearchingPLayersNumber[gameMode.toLowerCase()]}</span>
                </div>
              </div>
            </>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchingBody