import * as styles from '../../styles/scss/components/TeamSign/TeamSign.module.scss'
import { cdnLoader } from '../../config/cdnImageLoader.config';

const TeamSign = ({signName, active}) => {
  return (
    <div className={`${styles.team_sign} ${active && styles.active}`}>
      <img className={styles.logo_iamge} src={cdnLoader(`/images/teamIcons/${signName}.svg`)}/>
    </div>
  );
}

export default TeamSign;