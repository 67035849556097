import * as styles from "../../styles/scss/components/RecentAction/RecentAction.module.scss";
import Avatar from "../Avatar/Avatar";
import useTranslation from "next-translate/useTranslation";
import { cdnLoader } from "../../config/cdnImageLoader.config";
const RecentAction = (props) => {
  const {t} = useTranslation()
  const { cardStyle } = props
  return (
    <div className={styles.lastPlayer}>
      <Avatar
        size = 'small'
        hasFlag = {false}
        avatarUrls = {props.actionPlayer.user.avatar}
        onlineStatus = {props.actionType === 'leftGame' ? 'offline' : ''}
        color = {props.actionPlayer.color}
      />
      <div className={styles.lastAction}>
        {
          props.actionType === 'playCard' && <CardPlay 
            card = {props.card}
            cardStyle = {cardStyle}
          />
        }
        {
          props.actionType === 'dropCards' && <Dropped />
        }
        {
          props.actionType === 'stealCard' && 
          <StealCard
            targetPlayer = {props.targetPlayer}
            card = {props.card}
            cardStyle = {cardStyle}
          />
        }
        {
          props.actionType === 'swapMarbles' && <SwapMarbles
            targetPlayer = {props.targetPlayer}
            card = {props.card}
            cardStyle = {cardStyle}
          />
        }
        {
          props.actionType === 'leftGame' && <LeftGame />
        }
      </div>
    </div>
  );
};

const CardPlay = (props) => {
  const {t} = useTranslation()
  return (
    <div className={styles.cardPlay}>
      {props.card.code === 'JK' &&
        <>
          <div className={styles.cardValue}>
            <span className={styles.cardCode}>{t('common:modals.game_info.joker')}</span>
          </div>
          <span className={styles.standardText}>{t('common:modals.game_info.as')}</span>
        </>
      }
      <div className={styles.cardValue}>
        <span className={styles.cardCode}>{props.card.code !== 'JK' ? props.card.code : props.card.codeAlias}</span>
        {props.cardStyle.id !== 'easy' && (
          <img className={styles.cardType} src={cdnLoader(`/images/gameStats/${props.card.code !== 'JK' ? props.card.type : props.card.typeAlias}.svg`)} />
        )}
      </div>
    </div>
  )
}

const Dropped = (props) => {
  const {t} = useTranslation()
  return (
    <div className={styles.droppedCard}>
      <span className={`${styles.standardText} ${styles.danger}`}>
        {t('common:modals.game_info.dropped')}
      </span>
    </div>
  )
}

const SwapMarbles = (props) => {
  const {t} = useTranslation()
  return (
    <div className={styles.marbleSwap}>
      <div className={styles.cardValue}>
        <span className={styles.cardCode}>{props.card.code === 'JK' ? props.card.codeAlias : props.card.code}</span>
        {props.cardStyle.id !== 'easy' && (
          <img className={styles.cardType} src={cdnLoader(`/images/gameStats/${props.card.code === 'JK' ? props.card.typeAlias: props.card.type}.svg`)}/>
        )}
      </div>
      <div className={styles.swapIcon}>
        <img src={cdnLoader("/images/gameStats/exchangeIcon.png")} />
      </div>
      <Avatar
        size = 'small'
        hasFlag = {false}
        avatarUrls = {props.targetPlayer.user.avatar}
        color = {props.targetPlayer.color}
      />
    </div>
  )
}

const LeftGame = (props) => {
  const {t} = useTranslation()
  return (
    <div className={styles.leftGame}>
      <span className={`${styles.standardText} ${styles.danger}`}>{t('common:modals.game_info.left_the_game')}</span>
    </div>
  )
}

const StealCard = (props) => {
  const {t} = useTranslation()
  return (
    <div className={styles.stealCard}>
      <span className={styles.standardText}>{t('common:modals.game_info.stolen')}</span>
      <div className={styles.cardValue}>
        {props.card.visible ? (
          <>
            <span className={styles.cardCode}>{props.card.code}</span>
            {props.cardStyle.id !== 'easy' && (
              <img className={styles.cardType} src={cdnLoader(`/images/gameStats/${props.card.type}.svg`)}/>
            )}
          </>
        ) : (
          <span className={styles.cardCode}>{t('common:modals.game_info.card')}</span>
        )}
      </div>
      <Avatar
        size = 'small'
        hasFlag = {false}
        avatarUrls = {props.targetPlayer.user.avatar}
        color = {props.targetPlayer.color}
      />
    </div>
  )
}

export default RecentAction;
