import * as styles from '../../styles/scss/components/Card/Card.module.scss'
import { connect, useDispatch, useSelector } from 'react-redux';
// import { togglePreventCardClickWhilePLayingJoker } from '../../store/gameData/gameDataActions';
import React, { useCallback, useEffect, useState } from 'react';
import { setOfflineModalState,buzzOfflineModal  } from '../../store/modals/modalsActions';
import useScreenOrientation from "../../helpers/ScreenOrientation";
import api from '../../axios/api';
import gsap from 'gsap';
import {NEXT_PUBLIC_SERVER_ENVIRONMENT} from '../../config/env.config';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import {deviceType} from '../../helpers/functions.js'
import { cdnLoader } from '../../config/cdnImageLoader.config';

const Card = (props) => {
  const {
    card,
    onGameCommontCardEvent,
    // onSetActionCard,
    gameId,
    lobbyId,
    gamePublicActionChannel,
    // gameData,
    isVictim,
    socketId,
    isAlias,
    cardIndex,
    positions,
    playerIndex,
    openBoardCardsModule,
    isStealingMode,
    roundState,
    actionCard,
    cardPlayAction,
    myTurn,
    id,
    dropped,
    playingCard,
    playingMarble,
    width,
    height,
    cardMargin,
    chosenAsVictim,
    stealerId,
    responsive,
    stealingCard,
    cardLength,
    handleClickCount,
    device,
    victimPlayerChosenCardId,
    deviceForSetal,
    chosenVictimId,
    stealingInProgress,
    setStealingInProgress,
    forceFinished,
    onSetChosenVictim
    // onSetCardPlayAction
  } = props

  const [chosenCardForStealing, setChosenCardForStealing] = useState(null);
  const [victimId, setVIctimId] = useState(null);
  const [stealerIdFromSocket, setStealerIdFromSocket] = useState(null);
  const [showStealOverlayForStealer, setShowStealOverlayForStealer] = useState(false)
  const {cardStyle} = useSelector(state => state.settings)
  // const {preventCardClickWhilePlayingJoker} = useSelector(state => state.gameData)
  const {locale} = useRouter()
  // const cardStyle = {id: 'classic'}
  // const [CardMobileDimensionCoeficients, setCardMobileDimensionCoeficients]= useState({
  //   width: 64/740,
  //   height: 96/64
  // })
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const handleOpenOfflineModalState = (state) => {
    dispatch(setOfflineModalState(state))
  }
  const buzzModalHandler = (state) => {
    dispatch(buzzOfflineModal(state))
  }

  const orientation = useScreenOrientation() || (window.innerWidth > window.innerHeight ? "landscape-primary" : "portrait-primary")
  useEffect(() => {

    if(isVictim && chosenAsVictim){
      // if (id !== stealerId) {
      //   return
      // }
      // gsap.to(".victimPlayer .svgCards > g", { 

      gsap.to(".victimPlayer .victimCard > div", { 
        y: /Mobi|Android/i.test(navigator.userAgent) ? 210 : 200,  // 270
        scale: /Mobi|Android/i.test(navigator.userAgent) ? 1.8 : 1.5, // 1.8
        duration: .3 
      });
    }
  }, [isVictim, chosenAsVictim, isStealingMode]);

  useEffect(() => {
    if (!(isVictim && id === stealerId || id !== stealerId && chosenAsVictim)) {
      // gsap.to(".victimPlayer .victimCard > div", { 
      //   y: 0, 
      //   scale: 1, 
      //   duration: .3,
      //   onComplete: () => {
      //     console.log("DONE")
      //   }
      // });
    }
    
    // console.log(chosenAsVictim)
    // console.log(chosenVictimId)
    // if (chosenAsVictim === false) {
    //   gsap.to(".victimPlayer .playerCard > div", { 
    //     y: 0, 
    //     scale: 1, 
    //     duration: .3 
    //   });
    // }
    // console.log(chosenVictimId, chosenAsVictim)
  }, [isVictim, id, stealerId, chosenAsVictim])

  //just for testing
  const [showMenu, setShowMenu] = useState(false)

  const handleContextOpen = (e) => {
    if (NEXT_PUBLIC_SERVER_ENVIRONMENT !== 'production') {
      e.preventDefault();
      setShowMenu(prevstate => !prevstate)
    }
    // if (card.playerId === id) {
    //   e.preventDefault();
    //   setShowMenu(prevstate => !prevstate)
    // }
    // // if (NEXT_PUBLIC_SERVER_ENVIRONMENT == 'local' || NEXT_PUBLIC_SERVER_ENVIRONMENT == 'staging') {
    //   e.preventDefault();
    //   setShowMenu(prevstate => !prevstate)
    // // }
  }
  //

  useEffect(() => {
    if (!!gamePublicActionChannel) {
      return () => {
        // gamePublicActionChannel.unbind(`client-choosingForSteal`)
      }
    }
  }, [gamePublicActionChannel])

  const choosingCardForStealing = (cardId, stealerId, victimId) => {
    if (deviceForSetal === 'mobile') {
      return
    }
    if (isStealingMode && isVictim && myTurn && chosenAsVictim) {
      if (cardId) {
        setShowStealOverlayForStealer(true)
      } else {
        setShowStealOverlayForStealer(false)
      }
      gamePublicActionChannel.trigger('client-choosingForSteal', {
        cardId: cardId,
        stealer: stealerId,
        victim: victimId
      }); 
    }
  }

  // useEffect(() => {
  //   if (Object.keys(positions).length > 0) {
  //     const positions = getCardPositions()
  //     setCardPositions(positions)
  //   }
  // }, [positions])

  useEffect(() => {
    if (!!gamePublicActionChannel && !isStealingMode) {
      setChosenCardForStealing(null);
      gamePublicActionChannel.unbind(`client-choosingForSteal`);
    } else if (!!gamePublicActionChannel && isStealingMode) {
      gamePublicActionChannel.bind('client-choosingForSteal', (data) => {
        if (data.cardId === card.id) {
          setChosenCardForStealing(data.cardId)
          setVIctimId(data.victim)
          setStealerIdFromSocket(data.stealer)
        } else {
          setChosenCardForStealing(null)
        }
      })
    }
  }, [isStealingMode, gamePublicActionChannel])

  // useEffect(() => {
  //   console.log(props)
  // }, [])

  const getCardClassName = (card, chosenCardForStealing, playerIndex, myTurn, id, dropped, roundState, actionCard, isAlias) => {
    let className = styles.card
    if (card.boardCard || card.stealModalCard) {
      return className
    }
    if ((chosenCardForStealing === card.id) || (victimPlayerChosenCardId === card.id && deviceForSetal === 'mobile')) {
      className = className + ' ' + styles.highlighted + ' ' + styles[`highlighted_player_${playerIndex + 1}`]
    }

    // if(isVictim && chosenAsVictim){
    //   className = className + ' ' + "victimCard";
    // }

    if (
      ( myTurn && card.playerId === id && !card.canPlay && roundState === 'play') || 
      (card.playerId === id && dropped)
      // || playingCard
    ) {
      className = className + ' ' + styles.unplayable
    }

    if (
        ((myTurn && roundState === 'play') || roundState === 'exchange' ) && 
        card.playerId === id &&
        card.canPlay
    ) {
      className = className + ' ' + styles.playAble
    }

    if (roundState === 'exchange' && card.playerId === id) {
      className = className + ' ' + styles.cardInExchangeState
    }

    // debugger

    if (card.id === actionCard.id && card.playerId === id && roundState === 'exchange') {
      className = className + ' ' + styles.readyForExchange 
    }

    if (card.playerId === id && isAlias) {
      className = className + ' ' + styles.cardAlias
    }

    if(!myTurn && playerIndex === 0){
      className = className + ' ' + styles.notMyTurn
    }

    return className
  }


  const hanldeCardClick = async (event, card) => {
    // console.log('hanldeCardClick')
    // if(!preventCardClickWhilePlayingJoker || !(cardPlayAction === 'playAs' && isAlias)){
    //added for stealing
    if (isStealingMode && isVictim && !chosenAsVictim) {
      // console.log('isStealingMode && isVictim && !chosenAsVictim')
      gsap.to(".victimPlayer .playerCard > div", { 
        y: 0, 
        scale: 1, 
        duration: .3,
      });
      // debugger;
      if (id === stealerId) {
        // console.log("id === stealerId")
        gamePublicActionChannel.trigger('client-choosingVictim', {
          stealerId: stealerId,
          victimId: card.playerId
        })
        const requestData = {
          gameId: gameId,
          lobbyId: lobbyId,
          socketId: socketId,
          payload: {
            type: 'chooseVictim',
            victimId: card.playerId,
            cardId: actionCard.id
          },
          actionCard: {
            ...actionCard,
          }
        }
        await onGameCommontCardEvent(requestData)
  
        onSetChosenVictim(card.playerId)
      }
      return
    }
    //end of code
    if (
      // (isStealingMode && deviceForSetal === 'mobile') || 
      forceFinished) {
      console.log("isStealingMode && deviceForSetal === 'mobile') || forceFinished")
      return
    }
    if (chosenAsVictim) {
      // console.log("chosenAsVictim")
      event.stopPropagation();
    }
    // console.log('playing card------------>', playingCard)
    // console.log('stealing card------------>', stealingCard)
    if (card.stealModalCard) {
      // console.log("card.stealModalCard")
      return
    }
    if (card.boardCard) {
      // console.log("card.boardCard")
      openBoardCardsModule()
      return
    }
    if (!socketId && !card.modalCard) {
      // console.log("!socketId && !card.modalCard")
      handleOpenOfflineModalState(true)
      buzzModalHandler(true)
      return
    }
    const requestData = {
      gameId: gameId,
      lobbyId: lobbyId,
      socketId: socketId,
      actionCard: {
        ...card,
        transform: cardMargin ? cardMargin.x + 1 : actionCard.transform
      }
    }
    // onSetActionCard(card);
    if (roundState === 'exchange' && card.playerId === id) {      
      // console.log("roundState === 'exchange' && card.playerId === id")  
      requestData.payload = {
        type: "exchange",
        cardId: card.id  
      }
      
      handleClickCount();
      setTimeout(() => {
        onGameCommontCardEvent(requestData)
      }, 100)
      return
    } else if (roundState === 'play' && myTurn) {
      // console.log("roundState === 'play' && myTurn")
      if (!isStealingMode && card.canPlay && (!playingCard || card.jokerModalCard) && !playingMarble) {       
        // console.log("!isStealingMode && card.canPlay && (!playingCard || card.jokerModalCard) && !playingMarble") 
        if (cardPlayAction === 'playAs' && isAlias) {
          // console.log("cardPlayAction === 'playAs' && isAlias")
          // if (preventCardClickWhilePlayingJoker) {
          //   console.log("preventCardClickWhilePlayingJoker")
          //   return
          // }
          // dispatch(togglePreventCardClickWhilePLayingJoker(true))  
          requestData.payload = {
            type: 'playCard',
            cardId: card.id,
            playAs: {
              ...card
            },
            transform: actionCard.transform
          }
          
          // onSetActionCard(card);
          // setTimeout(() => {
            onGameCommontCardEvent(requestData);
          // }, 200)
          // console.log(gameData,'gameData');
          // onSetCardPlayAction('');
          return
        } else if (card.playerId === id && cardPlayAction === '') {
          // console.log("card.playerId === id && cardPlayAction === ''")
          const randomCard = Math.floor(Math.random() * 4);
          const cardRotation = [-11.74, 6.74, 6.68, 0];

          requestData.payload = {
            type: 'playCard',
            cardId: card.id,
            playAs: card.playAs,
            transform: cardRotation[randomCard]
          }

          // const boardCards = document.querySelector('.boardcard_holder');
          // const boardContainer = document.getElementById('board');
          // const boardCenter = document.querySelector('#centeredCardsPostion');
          // const left = document.querySelector('.playersContainerSVG.top');
          // const boardBg = document.querySelector('#boardBg');
          // const cardElement = document.getElementById(`_62ab1f06406986360f439641`);
          // // cardElement.parentElement.style.transformOrigin = '0px 0px';
          // let tl = gsap.timeline();
          // tl.to(cardElement.parentElement,
          //   {
          //     // rotation: cardRotation[randomCard] || 0,
          //     // x: Math.min(boardContainer.getBoundingClientRect().width, boardContainer.getBBox().width / 2) ,
          //     // x: (boardCenter.getBoundingClientRect().x) - cardElement.parentElement.parentElement.getBoundingClientRect().x,
          //     // y: (boardCenter.getBoundingClientRect().y) - cardElement.parentElement.parentElement.getBoundingClientRect().y,
          //     // x: boardCenter.getBoundingClientRect().x,
          //     // y: boardCenter.getBoundingClientRect().y,
          //     x: -300,
          //     y: 300,
          //     scale: 2,              
          //     duration: .1,
          //     onComplete: () => {
          //       tl.to(cardElement.parentElement, {
          //         opacity: 0,
          //         duration: .2,
          //         onComplete: () => {
                  
          //         }
          //       })
          //       // destBoardCard.classList.remove('empty');
          //       // destBoardCard.src = `/images/cards/svg/${data.action.card.codeAlias || data.action.card.code}${data.action.card.typeAlias || data.action.card.type}.svg`;
          //       // destBoardCard.style.opacity = 1;
          //       // destBoardCard.style.transform = `rotate(${cardRotation[randomCard] || 0}deg)`;
          //       gsap.delayedCall(.5, () => {
          //         // onHandleCardPlay(data.action);
          //       })
          //     }
          //   });
          const cardElement = document.getElementById(`_${card.id}`);
          if(cardElement){
            // console.log("cardElement")
            cardElement.parentElement.classList.add('played');
          }
          onGameCommontCardEvent(requestData);

        } else {
          // alert('can\'t play another players cards')
          // console.log("empty")
          return
        }
      } else {
        // console.log(" NOT !isStealingMode && card.canPlay && (!playingCard || card.jokerModalCard) && !playingMarble") 
        if (stealingCard || stealingInProgress || (!isStealingMode && id !== card.playerId) || (playingCard && id === card.playerId) ) {
          // console.log("stealingCard || stealingInProgress || (!isStealingMode && id !== card.playerId) || (playingCard && id === card.playerId)")
          return
        }
        setStealingInProgress && setStealingInProgress(true)
        if (isVictim && chosenAsVictim) {
          // console.log("isVictim && chosenAsVictim")
          // gsap.to(".svgCards .victimCard", { 
          requestData.payload = {
            type: 'steal',
            cardId: card.id,
            twoId: actionCard.id
          }
          gsap.to(".victimPlayer .playerCard > div", { 
            y: 0, 
            scale: 1, 
            duration: .3,
            onComplete: () => {
              onGameCommontCardEvent(requestData)
            }
          });
          
          return
        } else {
          // console.log("NOT isVictim && chosenAsVictim")
          // alert('you can\'t steal from that player')
        }
      }
    }
    // }
  }

  const getCardSrc = (cardCode, CardType, visible, isAlias, stealModalCard) => {
    // console.log(isAlias)
    if (cardStyle.id === 'easy') {
      let cardCodeInner = ''
      if (visible) {
        cardCodeInner = cardCode
        if (stealModalCard) {
          cardCodeInner = card.codeAlias || card.code
        } else if (isAlias) {
          cardCodeInner = 'JK' + card.codeAlias
        }        
      } else {
        cardCodeInner = 'back'
      }
      if(deviceType() == 'mobile' || deviceType() == 'tablet'){
        return `/images/cards/easy-compressed/${cardCodeInner}.svg`
      }
      return `/images/cards/easy/${cardCodeInner}.svg`
    }
    let cardName = ''
    if (!visible) {
      cardName = 'back'
    } else if (stealModalCard) {
      cardName = card.codeAlias ? card.codeAlias + card.typeAlias : card.code + card.type
    } else if (isAlias) {
      cardName = 'JK' + card.codeAlias + card.typeAlias
    } else if (cardCode !== 'JK') {
      cardName = cardCode + CardType
    } else {
      cardName = cardCode
    }
    // if(deviceType() == 'mobile' || deviceType() == 'tablet'){
    // return `/images/cards/classic-compressed/${cardName}.svg`
    // }

    return `/images/cards/classic/${cardName}.svg`
  }

  return (
    <>
      {/* {console.log('cards rendered')} */}
      {/* testing */}
      {showMenu && <ContextMenu 
        lobbyId = {lobbyId} 
        gameId = {gameId} 
        showMenu = {showMenu} 
        card = {card} 
        setShowMenu = {() =>{setShowMenu(false)}} 
        gamePublicActionChannel = {gamePublicActionChannel}
        userId = {id}
        />
      }
      {/* testing */}

      {card.modalCard || responsive ? (
        <div
          className = {`${getCardClassName(card, chosenCardForStealing, playerIndex, myTurn, id, dropped, roundState, actionCard, isAlias)} ${(id === stealerId &&chosenAsVictim && isStealingMode && deviceForSetal !== 'mobile') ? `${styles.showHover} ${styles[`highlighted_player_${playerIndex + 1}`]}` : ''}`}
          onClick= {(e) => hanldeCardClick(e,card)}
          onMouseOver = { (e) => {choosingCardForStealing(card.id)}}
          onMouseOut = { (e) => {choosingCardForStealing(null)}}
          datacardid = {card.id}
          id={`_${card.id}`}
          onContextMenu = {handleContextOpen}
          device={device}
          >
          {/* <Image
            src={`/images/cards/${getCardName(card.code, card.type, card.visible, card.isAlias, card.stealModalCard)}.svg`}
            alt="image of the card"
            layout="fill"
            quality={100}
          /> */}
          <img
          src={`${cdnLoader(getCardSrc(card.code, card.type, card.visible, card.isAlias, card.stealModalCard))}`}
          alt="image of the card"
          />
          <div className = {styles.exchange_overlay}>
            <button>{t('common:choose')}</button>
          </div>
          {card.exchanged && !card.modalCard &&(
            <div className = {`${styles.from_other_player} ${styles[card.takenFrom.color]}`}>
              <img src={cdnLoader('/images/gameModal/exchange.png')} />
            </div>
          )}
          {card.stolen && !card.modalCard &&(
            <div className = {`${styles.from_other_player} ${styles[card.takenFrom.color]}`}>
              <img src={cdnLoader('/images/gameModal/steal.png')} />
            </div>
          )}
        </div>
      ) : (
        <></>
      ) }
    </>
  )
}

const areEqual = (prevProps, nextProps) => {
  if (prevProps.isStealingMode !== nextProps.isStealingMode || prevProps.stealingInProgress !== nextProps.stealingInProgress || prevProps.playingMarble !== prevProps.playingMarble) {
    return false
  }
  if (prevProps.stealingCard !== nextProps.stealingCard && nextProps.chosenAsVictim) {
    // console.log('re rendering', prevProps.id)
    return false
  }
  if (nextProps.roundState === 'exchange' && nextProps.actionCard.id !== nextProps.card.id && prevProps.actionCard.id !== nextProps.card.id) {
    return true
  }
  if (nextProps.isStealingMode && !nextProps.isVictim) {
    return true
  } 
  else {
    return false
  }
}

export default React.memo(Card, areEqual)
// export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Card, areEqual))

//just for testing
const ContextMenu = (props) => {

  const {
    card,
    setShowMenu,
    lobbyId,
    gameId,
    gamePublicActionChannel,
    userId
  } = props

  const changeCard = (e) => {
    // console.log(e.target.innerHTML)
    gamePublicActionChannel.trigger('client-someoneChangedCard', {
      userId: userId
    });
    api.post(`/lobby/${lobbyId}/game/${gameId}/change-card`, {
      cardId: card.id,
      cardCode: e.target.innerHTML
    })
    .then(response => {
      // console.log(response)
      window.location.reload();
    }).catch(e => {
      // console.log(e)
    })
    setShowMenu()
  }
  return (
    <g width="100" height="100">
        <text x='0' y = '-50' onClick = {changeCard}>A</text>
        <text x='20' y = '-50'  onClick = {changeCard}>2</text>
        <text x='40' y = '-50'  onClick = {changeCard}>3</text>
        <text x='0' y = '-35' onClick = {changeCard}>4</text>
        <text x='20' y = '-35' onClick = {changeCard}>5</text>
        <text x='40' y = '-35' onClick = {changeCard}>6</text>
        <text x='0' y = '-20' onClick = {changeCard}>7</text>
        <text x='20' y = '-20' onClick = {changeCard}>8</text>
        <text x='40' y = '-20' onClick = {changeCard}>9</text>
        <text x='60' y = '-20' onClick = {changeCard}>10</text>
        <text x='0' y = '-5' onClick = {changeCard}>J</text>
        <text x='20' y = '-5' onClick = {changeCard}>Q</text>
        <text x='40' y = '-5' onClick = {changeCard}>K</text>
    </g>
  )
}