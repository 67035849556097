import * as styles from '../../styles/scss/components/FormInput/FormInput.module.scss';
import CheckSvg from '../../public/images/common/check.svg';
import CrossSvg from '../../public/images/common/cross.svg';
import EyeSvg from '../../public/images/common/eye.svg';
import SearchSvg from '../../public/images/common/search.svg';
import DropdownIcon from '../../public/images/common/dorpdown.svg';
import CalendarSvg from '../../public/images/common/calendar.svg';
import { useComponentVisible } from '../../helpers/hooks';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useRef, useState } from 'react';
import Loader from '../CustomLoader/Loader';
import useTranslation from 'next-translate/useTranslation';
import { cdnLoader } from '../../config/cdnImageLoader.config';
const FormInput = (props) => {
  const {
    type,
    value,
    name,
    change,
    active,
    inputId, 
    labelText, // text which is shown as placeholder
    valid, // true or false if input is valid
    showIndicator, // show success or error indicator
    errorText, // error text to show in ui
    disabled, // true or false if error is disabled
    showIcon, // show eye icon in input
    iconFunction, //what is happening on icon click
    reference, //for real time checking after user stops texting,
    changed, //detect when to show check or cross icons, also error texts
    focusHandler,
    clickHandler,
    autoFocus = false,
    textBelow
  } = props
  return (
    <div className = {`${styles.form_group} ${changed && !valid && styles.error} ${disabled && styles.disabled}`}>
      <div className = {styles.input_content}>
        <input 
          type = {type}
          value = {value} 
          name = {name}
          onChange = {(e) => {change(e)}} 
          className = {active ? styles.active : ''}
          htmlFor = {inputId}
          ref = {reference ? reference : null}
          autoComplete = 'off'
          disabled = {disabled}
          onFocus={() => {focusHandler && focusHandler(true)}}
          onBlur={() => {focusHandler && focusHandler(false)}}
          onClick={(e) => {e.preventDefault(); clickHandler && clickHandler(e)}}
          autoFocus = {autoFocus}
        />
        <label className = {`${styles.input_text} ${active && styles.active}`} id = {inputId}>{labelText}</label>
        {showIndicator && changed && (
          valid ? (
            <div className = {`${styles.indicator} ${styles.success}`}>
              <CheckSvg />
            </div>
          ) : (
            <div className = {`${styles.indicator} ${styles.fail}`}>
              <CrossSvg />
            </div>
          )
        )}
        {
          showIcon && (
            <div className = {`${styles.icon} ${showIndicator && styles.movedLeft}`} onClick = {iconFunction}>
              <EyeSvg />
            </div>
          )
        }
      </div>
      {changed && !valid && errorText && <span className = {styles.error_text}>{errorText}</span>}
      <p style={{
        marginTop: '1em',
        color: '#747678',
        fontFamily: 'FiraGo_Regular, sans-serif', 
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '12px'
        }}>{textBelow}</p>
    </div>
  )
}

export default FormInput

export const SearchInput = (props) => {
  const {
    reference,
    value,
    change,
    active,
    showIcon, // show eye icon in input
    iconFunction, //what is happening on icon click
  } = props

  const {t} = useTranslation()
  return (
    <div className = {`${styles.search_input} ${active && styles.active}`}>
      <input type = 'text' onChange = {change} value = {value} placeholder = {t('common:inputs.search')} ref = {reference}/>
      <div className = {styles.icon}>
        {showIcon ? (
          <div className = {styles.indicator} onClick = {iconFunction}>
            <CrossSvg />
          </div>
        ) : (
          <div className = {styles.search}>
            <SearchSvg />
          </div>
        )}
      </div>
    </div>
  )
}

export const Select = (props) => {
  const {
    value,
    change,
    active, 
    labelText, // text which is shown as placeholder
    disabled, // true or false if error is disabled
    showIcon, // show eye icon in input
    list,
    searchText,
    searchInputChange,
    hasSearch,
    loading,
    absolute,
    reference
  } = props

  const [showDropdown, setShowdropdown] = useState(false);

  const handleShowDropdown = () => {
    if (!disabled) {
      setIsComponentVisible(prevState => !prevState)
      return
    }
    // alert('can\'t choose now')
  }

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);


  const handleChooseItem = (listItem) => {
    change(listItem)
    handleShowDropdown()
  }

  return (
    <div className = {`${styles.select} ${disabled && styles.disabled} ${absolute && styles.absolute}`} ref = {ref}>
      <div className = {`${styles.select_input} ${value.length > 0 && styles.filled} ${isComponentVisible && styles.active}`} 
      onClick = {handleShowDropdown}>
        <span className = {styles.label_text}>{labelText}</span>
        <span className = {styles.chosen_value}>{value}</span>
        <div className = {styles.icon}>
          {loading ? <Loader loaderSize = 'size32'/> : <DropdownIcon />}
        </div>
      </div>
      <CSSTransition
        in = {isComponentVisible}
        timeout = {500}
        unmountOnExit
        classNames = 'select'
        >
        <div className = {`${styles.list} ${hasSearch && styles.with_search}`}>
          <div className = {styles.dropdown_search}>
            <SearchInput 
              value = {searchText}
              active= {searchText.length > 0}
              change = {(e) => searchInputChange(e.target.value)}
              showIcon = {searchText.length > 0} // show eye icon in input
              iconFunction = {() => searchInputChange('')} //what is happening on icon click
              reference = {reference}
            />
          </div>
          <ul className = {styles.list_ul}>
            {list && list.map(listItem => {
              return (
                <li 
                  className = {styles.list_li}
                  onClick = {() => {handleChooseItem(listItem)}}
                  key = {`${labelText}${listItem.id}`}
                  >
                  {listItem.name}
                </li>
              )
            })}
          </ul>
        </div>
      </CSSTransition>
    </div>
  )
}

export const MultiSelect = (props) => {
  const {
    selectedValues,
    change,
    list,
    searchText,
    searchInputChange,
    hasSearch,
    saveFunction
  } = props

  const handleChooseItem = (listItem) => {
    change(listItem.id)
  }

  return (
    <div className = {styles.multi_select}>
      {/* <div className = {`${styles.select_input} ${styles.active}`}>
        <span className = {styles.label_text}>{labelText}</span>
        <div className = {styles.icon}>
          <DropdownIcon />
        </div>
      </div> */}
      <div className = {`${styles.list} ${hasSearch && styles.with_search}`}>
        <div className = {styles.dropdown_search}>
          <SearchInput 
            value = {searchText}
            active= {searchText.length > 0}
            change = {(e) => searchInputChange(e.target.value)}
            showIcon = {searchText.length > 0} // show eye icon in input
            iconFunction = {() => searchInputChange('')} //what is happening on icon click
          />
        </div>
        <ul className = {styles.list_ul}>
          {list && list.map(listItem => {
            const selected = selectedValues.indexOf(listItem.id) >= 0
            return (
              <li 
                className = {styles.list_li}
                onClick = {() => {handleChooseItem(listItem)}}
                key = {`listItem${listItem.id}`}
                >
                <div className = {styles.item_container}>
                  <div className = {styles.check_container}>
                    <CheckBox checked = {selected} />
                  </div>
                  <div className = {styles.image_container}>
                    <img src = {cdnLoader(`/images/flags/${listItem.code}.svg`)}/>
                  </div>
                  <div className = {`${styles.listItem_title} ${selected && styles.selected}`}>
                    {listItem.name}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
        <div className = {styles.save_button_container}>
          <button onClick = {saveFunction}>Save</button>
        </div>
      </div>
    </div>
  )
}

export const CheckBox = ({checked}) => {
  return (
    <div className = {`${styles.checkbox} ${checked && styles.checked }`}>
      <CheckSvg />
    </div>
  )
}




export const DateInput = (props) => {
  const {title, saveDate, delimiter, date} = props
  const [chosenDate, setChosenDate] = useState({
    day: {
      value: '',
      maxLength: 2
    },
    month: {
      value: '',
      maxLength: 2
    },
    year: {
      value: '',
      maxLength: 4
    }
  })
  const setDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    setChosenDate(prevState => {return {
      ...prevState,
      day: {
        value: day < 10 ? '0'+day: day,
        maxLength: 2
      },
      month: {
        value: month < 10 ? '0'+month: month,
        maxLength: 2
      },
      year: {
        value: year,
        maxLength: 4
      }
    }})
  }
  useEffect(() => {
    // if (!!date) {
    //   setDate(date)
    // }
  }, [date])
  const handleSetInputValues = (e) => {
    const currentElement = e.target
    const nextInputElement = e.target?.nextElementSibling?.nextElementSibling
    if (isNaN(currentElement.value)) {
      return
    }
    if (currentElement.value.length >= chosenDate[currentElement.name].maxLength) {
      setChosenDate(prevState => {return {
        ...prevState,
        [currentElement.name]: {
          ...prevState[currentElement.name],
          value: currentElement.value.substr(0, chosenDate[currentElement.name].maxLength)
        }
      }})
      if (currentElement.getAttribute('data-order') !== 'last') {
        nextInputElement.focus()
      }
      return
    }
    setChosenDate(prevState => {return {
      ...prevState,
      [currentElement.name]: {
        ...prevState[currentElement.name],
        value: currentElement.value
      }
    }})
  }
  // const {
  //   ref,
  //   isComponentVisible,
  //   setIsComponentVisible
  // } = useComponentVisible(false);
  // useState (() => {
  //   console.log("visible ->", isComponentVisible);
  // }, [isComponentVisible])
  return (
    // <div ref={ref}>
    //   {!isComponentVisible && (
    //     <div onClick={() => setIsComponentVisible(true)}>
    //       <div>date of birth</div>
    //     </div>
    //   )}
    //   {isComponentVisible && (
    //     <div className = {styles.date_input}>
    //       <div className = {styles.date_inputs}>
    //         <h3 className = {styles.input_title}>
    //           {title}
    //         </h3>
    //         <div className = {styles.input_container}>
    //           <input type = 'text' name = 'day' data-order = 'first' placeholder = 'dd' value = {date.day.value} onChange = {handleSetInputValues}/>
    //           <span className = {styles.delimiter}>{delimiter}</span>
    //           <input type = 'text' name = 'month' placeholder = 'mm' value = {date.month.value}  onChange = {handleSetInputValues}/>
    //           <span className = {styles.delimiter}>{delimiter}</span>
    //           <input type = 'text' name = 'year' data-order = 'last' placeholder = 'yyyy' value = {date.year.value}  onChange = {handleSetInputValues}/>
    //         </div>
    //       </div>
    //       <div className = {styles.icon_container}>
    
    //       </div>
    //     </div>      
    //   )}
    // </div>
    <div className = {styles.date_input}>
      <div className = {styles.date_inputs}>
        <h3 className = {styles.input_title}>
          {title}
        </h3>
        <div className = {styles.input_container}>
          <input type = 'text' className = {styles.small} name = 'day' data-order = 'first' placeholder = 'dd' value = {chosenDate.day.value} onChange = {handleSetInputValues}/>
          <span className = {styles.delimiter}>{delimiter}</span>
          <input type = 'text' className = {styles.small} name = 'month' placeholder = 'mm' value = {chosenDate.month.value}  onChange = {handleSetInputValues}/>
          <span className = {styles.delimiter}>{delimiter}</span>
          <input type = 'text' className = {styles.large} name = 'year' data-order = 'last' placeholder = 'yyyy' value = {chosenDate.year.value}  onChange = {handleSetInputValues}/>
        </div>
      </div>
      <div className = {styles.icon_container}>
        <CalendarSvg />
      </div>
    </div> 
  );
}

export const Dropdown = (props) => {
  const {
    list,
    label,
    disabled,
    change,
    value,
    grid,
    icon,
    autoHeight
  } = props
  let style = {};
  if(autoHeight) {
    style.height = 'auto'
  }

  const [defaultState, setDefaultState] = useState({
    list: [],
    label: 'label',
    disabled: false,
    change: () => {console.log(value)},
    value: '',
    grid: false,
    small: false
  })

  const state = {...defaultState, ...props}

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const changeHandler = (value) => {
    state.change(value)
    setIsComponentVisible(false)
  }


  return (
    <div className = {`${styles.custom_dropdown} ${state.disabled && styles.disabled} ${state.small && styles.small}`} ref = {ref}>
      <div className = {`${styles.dorpdown_value} ${state.value && styles.filled} ${isComponentVisible && styles.active}`} onClick={() => setIsComponentVisible(prevState => !prevState)}>
        <h4 className = {styles.dorpdown_label}>
          {state.label}
        </h4>
        <h3 className = {styles.dropdown_value}>
          {state.value}
        </h3>
        <div className = {styles.icon_container}>
          <DropdownIcon />
        </div>
      </div>
      <CSSTransition
        in = {isComponentVisible}
        timeout = {500}
        unmountOnExit
        classNames = {autoHeight ? 'dropdownSmall' : 'dropdown'}
        >
        <div style={style} className = {styles.dropdown_list}>
          <ul className = {`${styles.dropdown_list_ul} ${state.grid && styles.grid}`}>
            {state.list.map(listItem => {
              if (!listItem.value) return
              return (
                <li className = {styles.list_item} key = {`dropdownItem${listItem.id}`}>
                  <div 
                    className = {`${styles.dropdown_item_container} ${listItem.value === state.value && styles.active}`} 
                    onClick = {() => {changeHandler(listItem)}}
                    >
                    <h3 className = {styles.list_item_text}>{listItem.value}</h3>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </CSSTransition>
    </div>
  )
}