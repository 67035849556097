import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN || 'https://3c2c004163644541b2a52c14ef8d9fdc@o919952.ingest.sentry.io/5864629',
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
        // Exclude specific requests (e.g., static assets like CSS or JS)
        const request = hint?.request?.url || '';
        if (request.includes('_next/static/') && /\.(css|js)$/.test(request)) {
            return null; // Skip reporting this event
        }

        return event;
    },
    beforeBreadcrumb(breadcrumb) {
        // Skip 'sentry-trace' for static asset requests
        if (breadcrumb?.type === 'http' && breadcrumb?.data?.url.includes('_next/static/')) {
            return null; // Remove the breadcrumb
        }

        return breadcrumb;
    },
});
