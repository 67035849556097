
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import * as styles from '../styles/scss/pages/errors/errors.module.scss';
import Head from 'next/head';
import { useDispatch } from 'react-redux';
import { setErrorsData } from '../store/errors/errorsActions';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { cdnLoader } from '../config/cdnImageLoader.config';
const NotAuthorized = () => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const router = useRouter();
  const goHomeHandler = () => {
    // router.push('/')
    localStorage.removeItem('access_token');
    dispatch(setErrorsData({errorOccured: false}))
    window.location.href = `/${router.locale}`
  }
  return (
    <section className={styles.page_container}>
      <Head>
        <meta name="robots" content="noindex" />
        {
          process.env.NEXT_ENABLE_GTAG == 'true' && (
            <>
              <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}></script>
              <script
                  dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                      page_path: window.location.pathname,
                    });
                  `,
                  }}
              />             
            </>
          )
        }
      </Head>
      <div className={styles.content_container}>
        <div className={styles.image_container}>
          <img src={cdnLoader('/images/error/401.png')} alt='not authorized img' />
        </div>
        <div className={styles.text_containers}>
          <h2 className={styles.error_title}>
            {t('errors:error_pages.401_title')}
          </h2>
          <p className={styles.descritpion}>
            {t('errors:error_pages.401_desc')}
          </p>
          <div className={styles.button_container}>
            <button onClick={goHomeHandler}>{t('common:buttons.go_home')}</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NotAuthorized

    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/401',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  