import React, {useCallback, useState} from 'react';

import * as styles from "../../styles/scss/components/GameStats/GameStats.module.scss";
import GameMember from "../GameMember/GameMember";
import RecentAction from "../RecentAction/RecentAction";
import Avatar from "../Avatar/Avatar";
import { CloseButton } from '../Buttons/Buttons';
import Marble from '../Board/Marble';
import MarblesColor from '../Board/Marble/MarblesColor';
import useTranslation from 'next-translate/useTranslation';
import { deviceType, nameShorten } from '../../helpers/functions'
import { cdnLoader } from '../../config/cdnImageLoader.config';

const GameStats = (props) => {
  const {
    roundNumber,
    thisRoundStarter,
    nextRoundStarter,
    recentActions,
    dealer,
    players,
    gameMode,
    versus,
    friendsList,
    rules,
    playersScoreboard,
    onFriendRequest,
    showGameInfo,
    onUpdatePlayerFriendshipStatus,
    gameName,
    cardStyle
  } = props

  const [mousePos, setMousePos] = useState({x:0, y:0});
  const {t} = useTranslation()
  const getGropuedPlayers = () => {
    const playersArray = [];
    if (players.length === 4) {
      playersArray.push(
        {
          teamName: players[0].stats.team.name,
          teamLogo: players[0].stats.team.logo,
          finishedMarbles: players[0].stats.finishedMarbles + players[2].stats.finishedMarbles,
          players: [...players.filter((elem, index) => index % 2 === 0)]
        },
        {
          teamName: players[1].stats.team.name,
          teamLogo: players[1].stats.team.logo,
          finishedMarbles: players[1].stats.finishedMarbles + players[3].stats.finishedMarbles,
          players: [...players.filter((elem, index) => index % 2 !== 0)]
        }
      )
    } else {
      if (versus === '2v2v2') {
        playersArray.push(
          {
            teamName: players[0].stats.team.name,
            teamLogo: players[0].stats.team.logo,  
            finishedMarbles: players[0].stats.finishedMarbles + players[3].stats.finishedMarbles,
            players: [{...players[0]}, {...players[3]}]
          },
          {
            teamName: players[1].stats.team.name,
            teamLogo: players[1].stats.team.logo,
            finishedMarbles: players[1].stats.finishedMarbles + players[4].stats.finishedMarbles,
            players: [{...players[1]}, {...players[4]}]
          },
          {
            teamName: players[2].stats.team.name,
            teamLogo: players[2].stats.team.logo,
            finishedMarbles: players[2].stats.finishedMarbles + players[5].stats.finishedMarbles,
            players: [{...players[2]}, {...players[5]}]
          },
        )
      }
      else {
        playersArray.push(
          {
            teamName: players[0].stats.team.name,
            teamLogo: players[0].stats.team.logo,
            finishedMarbles: players[0].stats.finishedMarbles + players[2].stats.finishedMarbles + players[4].stats.finishedMarbles,
            players: [...players.filter((elem, index) => index % 2 === 0)]
          },
          {
            teamName: players[3].stats.team.name,
            teamLogo: players[3].stats.team.logo,
            finishedMarbles: players[1].stats.finishedMarbles + players[3].stats.finishedMarbles + players[5].stats.finishedMarbles,
            players: [...players.filter((elem, index) => index % 2 !== 0)]
          },
        )
      }
    }
    return playersArray
  }

  const isInFriendList = (userId) => {
    for (let  i = 0; i < friendsList.length; i++) {
      if (friendsList[i].friend.id === userId) {
        return true
      }
    }
    return false
  }

  const handleFriendRequest = async (payload) => {
    await onFriendRequest(payload).then(response => {
      onUpdatePlayerFriendshipStatus(payload)
    }).catch(e => {
      console.log(e)
    })
  }
  
  const displayFullName = React.useCallback(e => {
    if(e.target.dataset.name?.length < 11){
      return;
    }
    e.target.nextElementSibling.style.display = 'block';
    mousePosition(e);
  }, [])

  const removeFullName = React.useCallback(e => {
    e.target.nextElementSibling.style.display = 'none';
  })

  const mousePosition = React.useCallback(e => {
    setMousePos({x: e.pageX, y: e.pageY})
  })

    
  return (
    <div className={`${styles.wrapper} ${styles[deviceType()]}`}>
      <div className={`${styles.infoCont}`}>
        <div className={`${styles.modalTitle}`}>
          <div className={styles.game_type}>
            <h2 className={`${styles.gameName}`}>{nameShorten(gameName)}</h2>
            <span className={styles.game_mode}>{t(`common:modals.game_info.${gameMode.toLowerCase()}`)} {t(`common:modals.game_info.mode`)}</span>
          </div>
          <div className={styles.game_type_close}>
            <CloseButton click={() => showGameInfo(false)}/>
          </div>
        </div>
        <div className={styles.gamestats_body}>          
            <div className = {styles.players_information}>          
              <h2 className = {styles.content_title}>{t('common:modals.game_info.score')}</h2>
              <div className = {`${styles.information_container} ${(gameMode === "team" && styles.team_mode)}`}>
                {gameMode === 'team' && (
                  getGropuedPlayers().map((group, index) => {
                    return (
                      <div className = {`${styles.team_group}`} key = {`group${index}`}>
                        <div className = {styles.team_stats}>
                          <GameMember teamInfo = {{
                            name: group.teamName,
                            stat: group.finishedMarbles,
                            logo: group.teamLogo,
                            players: group.players
                          }}
                          displayFullName={displayFullName}
                          removeFullName={removeFullName}
                          mousePos={mousePos}/>
                        </div>                    
                        <ul className = {styles.player_information_ul}>
                          {group.players.map(player => {
                            return (
                              <li key = {`info${player.id}`} className = {styles.player_information_li}>
                                <div className = {styles.player_container}>
                                  <div className = {styles.avatar_container}>
                                    <Avatar
                                      size = 'size48'
                                      mode = 'normal'
                                      color = {player.color}
                                      avatarUrls = {player.user.avatar}
                                      country = {player.user.countryCode ? player.user.countryCode : '00'}
                                    />
                                  </div>
                                  <div className = {styles.user_name_container}>
                                    <h2 data-name={player.user.username} onMouseOver={displayFullName} onMouseOut={removeFullName} className = {styles.user_name}>
                                      {(player.user.username).length > 10 ? `${(player.user.username).substring(0,10)}...` : (player.user.username)}
                                    </h2>
                                    <span style={{left: mousePos.x}} className={styles.player_fullname}>{player.user.username}</span>
                                  </div>
                                  {!player.currentPlayer && (
                                    <div className = {styles.add_friend_button_container}>
                                      {player.user.friendshipStatus === 'pending' && (
                                        <button className = {`${styles.add_friend_button} ${styles.add_friend_button_pending}`} disabled>
                                          {/* {t('common:pending')} */}
                                          <img src={cdnLoader("/images/gameModal/add-friend.svg")}/>
                                        </button>
                                      )}
                                      {!player.user.friendshipStatus&& (
                                        <button className = {styles.add_friend_button} onClick = {() => {handleFriendRequest({recipient:player.user.id})}}>
                                        {/* {t('common:buttons.add_as_friend')} */}
                                          <img src={cdnLoader("/images/gameModal/add-friend.svg")}/>
                                        </button>
                                    )}
                                    </div>
                                  )}
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                  })
                )}
                {gameMode === 'solo' && (
                  <ul style={{justifyContent: players.length > 2 ? "space-between" : "flex-start"}} className = {styles.player_information_ul}>
                    {players.map((player, index) => {
                      return (
                        <li style={{marginRight: players.length < 3 ? "30px" : 0 }} key = {`info${player.id}`} className = {styles.player_information_li}>
                          <div className = {styles.player_container}>
                            <div className = {styles.finished_marbles_count}>
                              <div className={styles.finished_marbles_count_marbles}>
                                <svg width="32" height="32">
                                  <circle cx="16" cy="16" r="13" strokeWidth='6' stroke={`url('#${player.color}Stroke')`} fill="none" />
                                  <Marble color={player.color} transform="translate(3,3)" width="26" height="26" rx="5.9" ry="5.9" />
                                </svg>
                              </div>
                              <span className = {styles.finished_marbles_number}>{player.stats.finishedMarbles}</span>
                            </div>
                            <div className = {styles.avatar_container}>
                              <Avatar
                                size = 'size48'
                                mode = 'normal'
                                color = {player.color}
                                avatarUrls = {player.user.avatar}
                                country = {player.user.countryCode ? player.user.countryCode : '00'}
                              />
                            </div>
                            <div className = {styles.user_name_container}>
                              <h2 className = {styles.user_name}>
                                {player.user.username}
                              </h2>
                            </div>
                            {!player.currentPlayer && (
                              <div className = {styles.add_friend_button_container}>
                                {player.user.friendshipStatus === 'pending' && (
                                  <button className = {`${styles.add_friend_button} ${styles.add_friend_button_pending}`} disabled>
                                    {/* {t('common:pending')} */}
                                    <img src={cdnLoader("/images/gameModal/add-friend.svg")} />
                                  </button>
                                )}
                                {!player.user.friendshipStatus && (
                                  <button className = {styles.add_friend_button} onClick = {() => {handleFriendRequest({recipient:player.user.id})}}>
                                    {/* {t('common:buttons.add_as_friend')} */}
                                    <img src={cdnLoader("/images/gameModal/add-friend.svg")} />
                                  </button>
                              )}
                              </div>
                            )}
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
            </div>
            {/* <div className={styles.container}>
              <GameMember />
              <GameMember />
            </div> */}
            <div className={styles.gamestats_body_container}>
              <div className = {styles.game_info}>
                <h2 className = {styles.content_title}>Game Info</h2>
                <div className={`${styles.gameDetails}`}>
                  <h2 className={`${styles.roundText}`}>{t('common:modals.game_info.round')} <span>{roundNumber}</span></h2>
                  <span className={`${styles.titleText}`}>{t('common:modals.game_info.this_round')}</span>
                  <div className={`${styles.commonAction} ${styles.mgBottom5}`}>
                    <div className={`${styles.flexCont}`}>
                      <Avatar
                        size="small"
                        hasFlag = {false}
                        avatarUrls = {dealer.user.avatar}
                        color = {dealer.color}
                        // avatarUrls = {}
                      />
                      <div className={styles.dealText}>
                        <span className={`${styles.textBold}`}>{t('common:modals.game_info.deals')}</span>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.commonAction}`}>
                    <div className={`${styles.flexCont}`}>
                      <Avatar
                        size="small"
                        hasFlag = {false}
                        avatarUrls = {thisRoundStarter.user.avatar}
                        color = {thisRoundStarter.color}
                      />
                      <div className={styles.dealText}>
                        <span className={`${styles.textBold}`}>{t('common:modals.game_info.starts')}</span>
                        <span className={`${styles.textGeneral}`}>{t('common:modals.game_info.with')}</span>
                        <span className={`${styles.dealCardsNumber}`}>{thisRoundStarter.cardCount}</span>
                      </div>
                    </div>
                  </div>
                  <span className={`${styles.titleText} ${styles.mgTop}`}>
                  {t('common:modals.game_info.next_round')}
                  </span>
                  <div className={`${styles.commonAction}`}>
                      <div className={`${styles.flexCont}`}>
                        <Avatar
                          size="small"
                          hasFlag = {false}
                          avatarUrls = {nextRoundStarter.user.avatar}
                          color = {nextRoundStarter.color}
                          />
                          <div className={styles.dealText}>
                            <span className={`${styles.textBold}`}>{t('common:modals.game_info.starts')}</span>
                            <span className={`${styles.textGeneral}`}>{t('common:modals.game_info.with')}</span>
                            <span className={`${styles.dealCardsNumber}`}>{nextRoundStarter.cardCount}</span>
                          </div>
                      </div>
                    </div>
                </div>
                <div className={`${styles.recentActions}`}>
                  <h2 className={`${styles.titleRecentActions}`}>{t('common:modals.game_info.recent_actions')}</h2>
                  {recentActions.map((recentAction, index) => {
                    return (
                      <div
                      className={`${styles.actionCont} ${styles.actionCont}`}
                      key = {`recentAction${index}`}
                      >
                        <RecentAction 
                          actionType = {recentAction.type}
                          card = {recentAction.card}
                          actionPlayer = {recentAction.actionPlayer}
                          targetPlayer = {recentAction.targetPlayer}
                          cardStyle = {cardStyle}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>          
              <div className = {styles.rules}>
                <h2 className = {styles.content_title}>{t('common:modals.game_search.rules')}</h2>
                <ul className = {styles.rules_ul}>
                  <li className = {styles.rules_li}>
                    <div className = {styles.rule_container}>
                      <div className = {styles.rule_image_container}>
                        <img src = {cdnLoader(`/images/rules/sbd.svg`)} />
                      </div>
                      <span className = {styles.rule_title}>{t('rules:standard')}</span>
                    </div>
                  </li>
                  {rules.map(rule => {
                    if (gameMode === 'team' && rule.symbol === 'js') return
                    if (gameMode === 'solo' && (rule.symbol === 'cs' || rule.symbol === 'ccs')) return
                    if (rule.symbol === 'ccs' || rule.symbol === 'any') return
                    return (
                      <li className = {styles.rules_li} key = {`gameStatsRule${rule.symbol}`}>
                        <div className = {styles.rule_container}>
                          <div className = {styles.rule_image_container}>
                            <img src = {cdnLoader(`/images/rules/${rule.symbol}.svg`)} />
                          </div>
                          <span className = {styles.rule_title}>
                            {t(`rules:${rule.symbol}`)}
                          </span>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default GameStats;
