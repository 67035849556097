import * as actionTypes from './notificationActionTypes';

const initialState = {
  isLoading: false,
  notifications: [],
  error: null,
  lastNotifications: [],
  unreadNotificationCount: null,
  stopSendingRequests: false,
  pagIndex: 0
}

const getLastNotification = (notifications) => {
  const lastNotifications = [];
  const lastNotifCount = 3;
  for (let i = 0; i < lastNotifCount; i++) {
    if (notifications[i]) {
      lastNotifications.push(notifications[i])
    }
  }
  return lastNotifications;
}

const getUnreadNotificationCount = (notifications) => {
  let unreadCounter = 0;
  for (let notification of notifications) {
    if (!notification.readAt) {
      unreadCounter ++;
    }
  }
  return unreadCounter
}

const markNotificationsAsRead = (newNotifications, oldNotifications) => {
  const notifications = [...oldNotifications]
  for (let i = 0; i < notifications.length; i++) {
    for (let j = 0; j < newNotifications.length; j++) {
      if (notifications[i].id === newNotifications[j].id) {
        notifications[i] = {
          ...notifications[i],
          readAt: newNotifications[j].readAt
        }
      }
    }
  }
  return notifications
}

const getRestructuredNotification = (notification) => {
  const data = {
    ...notification,
    notificationModalType: '',
    accept: null,
    decline: null
  }
  switch(notification.type) {
    case 'MatchReady': {
      data.notificationModalType = 'text';
      data.title = 'online_game';
      data.text = (data.message === 'NO_ACTION' || data.message === 'GAME_FINISHED') ? 'game_finished' : 'game_started'
      data.user = {
        avatar : {
          normal: '/images/rules/sbd.svg',
          stealing: '/images/rules/sbd.svg',
        }
      }
      data.title_translation_needed = true
    } break;
    case 'FriendshipRequested': {
      data.notificationModalType = 'withButtons';
      data.title = notification.user.username;
      data.text = 'friend_request_text'
    }; break;
    case 'FriendshipAccepted':  {
      data.notificationModalType = 'text';
      data.title = notification.user.username;
      data.text = 'friend_request_accepted'
    } break;
    case 'GameRequested': {
      data.notificationModalType = 'withButtons';
      data.title = notification.host.name || notification.host.username;
      if (notification.message === 'NO_ACTION') {
        data.text = `Lobby has been deleted by host: ${notification.host.username}`;
        data.connected_translation = true,
        data.text_1 = "lobby_deleted_text";
        data.text_2 = notification.host.username;
      } else {
        if (notification.message === "REDIRECT_TO_LOBBY") {
          data.text = `you have been invited in friends game: ${notification.lobby.name}`;
          data.connected_translation = true,
          data.text_1 = "friends_game_invite_text";
          data.text_2 = notification.lobby.name  
        } else if (notification.message === "REDIRECT_TO_GAME") {
          data.text = 'game_started';
        } else if (notification.message === 'GAME_FINISHED') {
          data.text = 'game_finished';
        }
      }
      data.user = {...notification.host};
    }
  }
  return data
}

const getRestructuredNotifications = (notifications) => {
  const restructuredNotifications = [];
  for (let notification of notifications) {
    const restructuredNotification = getRestructuredNotification(notification)
    restructuredNotifications.push(restructuredNotification)
  }
  return restructuredNotifications
}

const updateNotificationMessageText = (notificationId, notificationText, notifications) => {
  const newNotifications = notifications.map(notification => {
    if (notification.id === notificationId) {
      const newNotificationObj = {...getRestructuredNotification({
        ...notification,
      })}
      newNotificationObj.message = notificationText
      return newNotificationObj
    } else {
      return {
        ...notification
      }
    }
  })
  return newNotifications
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.GET_NOTIFICATIONS_REQUEST:
        return {
          ...state,
          error: null,
          isLoading: true,
        };
      case actionTypes.GET_NOTIFICATIONS_SUCCESS: {
        const restructuredNotifications = [...getRestructuredNotifications(state.notifications), ...getRestructuredNotifications(action.payload.notifications)]
        return {
          ...state,
          isLoading: false,
          notifications: restructuredNotifications,
          lastNotifications: [...getLastNotification(restructuredNotifications)],
          unreadNotificationCount: getUnreadNotificationCount(action.payload.notifications),
          totalCount: action.payload.totalCount || state.totalCount,
        }
      }
      case actionTypes.SET_NEW_NOTIFCATION: {
        let oldNotifications = [...state.notifications]
        let unreadNotificationCount = state.unreadNotificationCount
        if (action.payload.notification.type === 'friendAccepted') {
          oldNotifications = [...oldNotifications.map(notification => {
            if (notification.id === action.payload.notification.notificationId) {
              return {
                ...notification,
                friendship: {
                  ...notification.friendship,
                  status: 'accepted'
                }
              }
            } else {
              return {
                ...notification
              }
            }
          })]
          unreadNotificationCount --
        } else if (action.payload.notification.type === 'friendDeclined'){
          oldNotifications = [...oldNotifications.map(notification => {
            if (notification.id === action.payload.notification.notificationId) {
              return {
                ...notification,
                friendship: {
                  ...notification.friendship,
                  status: 'declined'
                }
              }
            } else {
              return {
                ...notification
              }
            }
          })]
          unreadNotificationCount --
        } 
        else {
          const notification = {...action.payload.notification};
          oldNotifications = [getRestructuredNotification(notification), ...oldNotifications]
        }
        return {
          ...state,
          notifications: oldNotifications,
          lastNotifications: [...getLastNotification(oldNotifications)],
          unreadNotificationCount: unreadNotificationCount + 1
        }
      }
      case actionTypes.GET_NOTIFICATIONS_FAIL: {
        return {
          isLoading: false,
          error: action.payload.error
        }
      }
      case actionTypes.MARK_NOTIFICATION_AS_READ_REQUEST: {
        return {
          ...state
        }
      };
      case actionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS: {
        // const restructuredNotifications = [...getRestructuredNotifications(action.payload.notifications)]
        const markAsReadStatusChanged = [...markNotificationsAsRead(action.payload.notifications, state.notifications)]
        return {
          ...state,
          notifications: markAsReadStatusChanged,
          lastNotifications: [...getLastNotification(markAsReadStatusChanged)],
          unreadNotificationCount: state.unreadNotificationCount - action.payload.notifications.length
        }
      };
      case actionTypes.CHANGE_GAME_REQUEST_NOTIFICATION_MESSAGE: {
        const {message, notificationId} = action.payload
        const newNotifications = updateNotificationMessageText(notificationId, message, [...state.notifications])
        return {
          ...state,
          lastNotifications: [...getLastNotification(newNotifications)],
          notifications: [...newNotifications]
        }
      }
      case actionTypes.MAKE_ACTION_SUCCESS: {
        let idx = -1;
        for(let i = 0; i < state.notifications.length; i++) {
          if(state.notifications[i].id === action.payload.id) {
            idx = i;
          }
        }
        if (idx === -1) {
          return state;
        }
        const notification = getRestructuredNotification(action.payload);
        state.notifications.splice(idx, 1, notification)
        // debugger;
        return {
          ...state,
          notifications: [...state.notifications],
        }
      }
      // case actionTypes.DELETE_NOTIFICATIONS : {
      //   return {
      //     ...state,
      //     notifications: []
      //   }
      // }
      case actionTypes.PAG_INDEX: {
        return {
          ...state,
          pagIndex: action.payload.pagIndex
        }
      }
      default:
        return state;
    }
}

export default notificationReducer;